"use client";

import React from "react";
import { MoonIcon, SunIcon } from "@heroicons/react/24/outline";
import { useTheme } from "next-themes";
import { useIsMounted } from "@/hooks/useIsMounted";

export function ThemeButton() {
  const { resolvedTheme, setTheme } = useTheme();
  const isMounted = useIsMounted();

  if (!isMounted.current) {
    return <></>;
  }

  return (
    <button
      className="text-icon-light hover:text-iconHighlight-light dark:text-icon-dark hover:dark:text-iconHighlight-dark"
      onClick={() => setTheme(resolvedTheme === "dark" ? "light" : "dark")}
    >
      {resolvedTheme === "dark" ?
        <SunIcon className="h-5 w-5" aria-hidden="true" />
      : <MoonIcon className="h-5 w-5" aria-hidden="true" />}
    </button>
  );
}
