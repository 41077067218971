import {
  getContract,
  GetContractArgs,
  readContract,
  ReadContractConfig,
  writeContract,
  WriteContractArgs,
  WriteContractPreparedArgs,
  WriteContractUnpreparedArgs,
  prepareWriteContract,
  PrepareWriteContractConfig,
} from "wagmi/actions";

//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// Allo
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

export const alloABI = [
  {
    stateMutability: "view",
    type: "function",
    inputs: [],
    name: "DEFAULT_ADMIN_ROLE",
    outputs: [{ name: "", internalType: "bytes32", type: "bytes32" }],
  },
  {
    stateMutability: "view",
    type: "function",
    inputs: [],
    name: "NATIVE",
    outputs: [{ name: "", internalType: "address", type: "address" }],
  },
  {
    stateMutability: "nonpayable",
    type: "function",
    inputs: [
      { name: "_poolId", internalType: "uint256", type: "uint256" },
      { name: "_manager", internalType: "address", type: "address" },
    ],
    name: "addPoolManager",
    outputs: [],
  },
  {
    stateMutability: "nonpayable",
    type: "function",
    inputs: [{ name: "_strategy", internalType: "address", type: "address" }],
    name: "addToCloneableStrategies",
    outputs: [],
  },
  {
    stateMutability: "payable",
    type: "function",
    inputs: [
      { name: "_poolId", internalType: "uint256", type: "uint256" },
      { name: "_data", internalType: "bytes", type: "bytes" },
    ],
    name: "allocate",
    outputs: [],
  },
  {
    stateMutability: "nonpayable",
    type: "function",
    inputs: [
      { name: "_poolIds", internalType: "uint256[]", type: "uint256[]" },
      { name: "_datas", internalType: "bytes[]", type: "bytes[]" },
    ],
    name: "batchAllocate",
    outputs: [],
  },
  {
    stateMutability: "nonpayable",
    type: "function",
    inputs: [
      { name: "_poolIds", internalType: "uint256[]", type: "uint256[]" },
      { name: "_data", internalType: "bytes[]", type: "bytes[]" },
    ],
    name: "batchRegisterRecipient",
    outputs: [
      { name: "recipientIds", internalType: "address[]", type: "address[]" },
    ],
  },
  {
    stateMutability: "payable",
    type: "function",
    inputs: [],
    name: "cancelOwnershipHandover",
    outputs: [],
  },
  {
    stateMutability: "payable",
    type: "function",
    inputs: [
      { name: "pendingOwner", internalType: "address", type: "address" },
    ],
    name: "completeOwnershipHandover",
    outputs: [],
  },
  {
    stateMutability: "payable",
    type: "function",
    inputs: [
      { name: "_profileId", internalType: "bytes32", type: "bytes32" },
      { name: "_strategy", internalType: "address", type: "address" },
      { name: "_initStrategyData", internalType: "bytes", type: "bytes" },
      { name: "_token", internalType: "address", type: "address" },
      { name: "_amount", internalType: "uint256", type: "uint256" },
      {
        name: "_metadata",
        internalType: "struct Metadata",
        type: "tuple",
        components: [
          { name: "protocol", internalType: "uint256", type: "uint256" },
          { name: "pointer", internalType: "string", type: "string" },
        ],
      },
      { name: "_managers", internalType: "address[]", type: "address[]" },
    ],
    name: "createPool",
    outputs: [{ name: "poolId", internalType: "uint256", type: "uint256" }],
  },
  {
    stateMutability: "payable",
    type: "function",
    inputs: [
      { name: "_profileId", internalType: "bytes32", type: "bytes32" },
      { name: "_strategy", internalType: "address", type: "address" },
      { name: "_initStrategyData", internalType: "bytes", type: "bytes" },
      { name: "_token", internalType: "address", type: "address" },
      { name: "_amount", internalType: "uint256", type: "uint256" },
      {
        name: "_metadata",
        internalType: "struct Metadata",
        type: "tuple",
        components: [
          { name: "protocol", internalType: "uint256", type: "uint256" },
          { name: "pointer", internalType: "string", type: "string" },
        ],
      },
      { name: "_managers", internalType: "address[]", type: "address[]" },
    ],
    name: "createPoolWithCustomStrategy",
    outputs: [{ name: "poolId", internalType: "uint256", type: "uint256" }],
  },
  {
    stateMutability: "nonpayable",
    type: "function",
    inputs: [
      { name: "_poolId", internalType: "uint256", type: "uint256" },
      { name: "_recipientIds", internalType: "address[]", type: "address[]" },
      { name: "_data", internalType: "bytes", type: "bytes" },
    ],
    name: "distribute",
    outputs: [],
  },
  {
    stateMutability: "payable",
    type: "function",
    inputs: [
      { name: "_poolId", internalType: "uint256", type: "uint256" },
      { name: "_amount", internalType: "uint256", type: "uint256" },
    ],
    name: "fundPool",
    outputs: [],
  },
  {
    stateMutability: "view",
    type: "function",
    inputs: [],
    name: "getBaseFee",
    outputs: [{ name: "", internalType: "uint256", type: "uint256" }],
  },
  {
    stateMutability: "pure",
    type: "function",
    inputs: [],
    name: "getFeeDenominator",
    outputs: [
      { name: "FEE_DENOMINATOR", internalType: "uint256", type: "uint256" },
    ],
  },
  {
    stateMutability: "view",
    type: "function",
    inputs: [],
    name: "getPercentFee",
    outputs: [{ name: "", internalType: "uint256", type: "uint256" }],
  },
  {
    stateMutability: "view",
    type: "function",
    inputs: [{ name: "_poolId", internalType: "uint256", type: "uint256" }],
    name: "getPool",
    outputs: [
      {
        name: "",
        internalType: "struct IAllo.Pool",
        type: "tuple",
        components: [
          { name: "profileId", internalType: "bytes32", type: "bytes32" },
          {
            name: "strategy",
            internalType: "contract IStrategy",
            type: "address",
          },
          { name: "token", internalType: "address", type: "address" },
          {
            name: "metadata",
            internalType: "struct Metadata",
            type: "tuple",
            components: [
              { name: "protocol", internalType: "uint256", type: "uint256" },
              { name: "pointer", internalType: "string", type: "string" },
            ],
          },
          { name: "managerRole", internalType: "bytes32", type: "bytes32" },
          { name: "adminRole", internalType: "bytes32", type: "bytes32" },
        ],
      },
    ],
  },
  {
    stateMutability: "view",
    type: "function",
    inputs: [],
    name: "getRegistry",
    outputs: [
      { name: "", internalType: "contract IRegistry", type: "address" },
    ],
  },
  {
    stateMutability: "view",
    type: "function",
    inputs: [{ name: "role", internalType: "bytes32", type: "bytes32" }],
    name: "getRoleAdmin",
    outputs: [{ name: "", internalType: "bytes32", type: "bytes32" }],
  },
  {
    stateMutability: "view",
    type: "function",
    inputs: [{ name: "_poolId", internalType: "uint256", type: "uint256" }],
    name: "getStrategy",
    outputs: [{ name: "", internalType: "address", type: "address" }],
  },
  {
    stateMutability: "view",
    type: "function",
    inputs: [],
    name: "getTreasury",
    outputs: [{ name: "", internalType: "address payable", type: "address" }],
  },
  {
    stateMutability: "nonpayable",
    type: "function",
    inputs: [
      { name: "role", internalType: "bytes32", type: "bytes32" },
      { name: "account", internalType: "address", type: "address" },
    ],
    name: "grantRole",
    outputs: [],
  },
  {
    stateMutability: "view",
    type: "function",
    inputs: [
      { name: "role", internalType: "bytes32", type: "bytes32" },
      { name: "account", internalType: "address", type: "address" },
    ],
    name: "hasRole",
    outputs: [{ name: "", internalType: "bool", type: "bool" }],
  },
  {
    stateMutability: "nonpayable",
    type: "function",
    inputs: [
      { name: "_owner", internalType: "address", type: "address" },
      { name: "_registry", internalType: "address", type: "address" },
      { name: "_treasury", internalType: "address payable", type: "address" },
      { name: "_percentFee", internalType: "uint256", type: "uint256" },
      { name: "_baseFee", internalType: "uint256", type: "uint256" },
    ],
    name: "initialize",
    outputs: [],
  },
  {
    stateMutability: "view",
    type: "function",
    inputs: [{ name: "_strategy", internalType: "address", type: "address" }],
    name: "isCloneableStrategy",
    outputs: [{ name: "", internalType: "bool", type: "bool" }],
  },
  {
    stateMutability: "view",
    type: "function",
    inputs: [
      { name: "_poolId", internalType: "uint256", type: "uint256" },
      { name: "_address", internalType: "address", type: "address" },
    ],
    name: "isPoolAdmin",
    outputs: [{ name: "", internalType: "bool", type: "bool" }],
  },
  {
    stateMutability: "view",
    type: "function",
    inputs: [
      { name: "_poolId", internalType: "uint256", type: "uint256" },
      { name: "_address", internalType: "address", type: "address" },
    ],
    name: "isPoolManager",
    outputs: [{ name: "", internalType: "bool", type: "bool" }],
  },
  {
    stateMutability: "view",
    type: "function",
    inputs: [],
    name: "owner",
    outputs: [{ name: "result", internalType: "address", type: "address" }],
  },
  {
    stateMutability: "view",
    type: "function",
    inputs: [
      { name: "pendingOwner", internalType: "address", type: "address" },
    ],
    name: "ownershipHandoverExpiresAt",
    outputs: [{ name: "result", internalType: "uint256", type: "uint256" }],
  },
  {
    stateMutability: "nonpayable",
    type: "function",
    inputs: [
      { name: "_token", internalType: "address", type: "address" },
      { name: "_recipient", internalType: "address", type: "address" },
    ],
    name: "recoverFunds",
    outputs: [],
  },
  {
    stateMutability: "payable",
    type: "function",
    inputs: [
      { name: "_poolId", internalType: "uint256", type: "uint256" },
      { name: "_data", internalType: "bytes", type: "bytes" },
    ],
    name: "registerRecipient",
    outputs: [{ name: "", internalType: "address", type: "address" }],
  },
  {
    stateMutability: "nonpayable",
    type: "function",
    inputs: [{ name: "_strategy", internalType: "address", type: "address" }],
    name: "removeFromCloneableStrategies",
    outputs: [],
  },
  {
    stateMutability: "nonpayable",
    type: "function",
    inputs: [
      { name: "_poolId", internalType: "uint256", type: "uint256" },
      { name: "_manager", internalType: "address", type: "address" },
    ],
    name: "removePoolManager",
    outputs: [],
  },
  {
    stateMutability: "payable",
    type: "function",
    inputs: [],
    name: "renounceOwnership",
    outputs: [],
  },
  {
    stateMutability: "nonpayable",
    type: "function",
    inputs: [
      { name: "role", internalType: "bytes32", type: "bytes32" },
      { name: "account", internalType: "address", type: "address" },
    ],
    name: "renounceRole",
    outputs: [],
  },
  {
    stateMutability: "payable",
    type: "function",
    inputs: [],
    name: "requestOwnershipHandover",
    outputs: [],
  },
  {
    stateMutability: "nonpayable",
    type: "function",
    inputs: [
      { name: "role", internalType: "bytes32", type: "bytes32" },
      { name: "account", internalType: "address", type: "address" },
    ],
    name: "revokeRole",
    outputs: [],
  },
  {
    stateMutability: "view",
    type: "function",
    inputs: [{ name: "interfaceId", internalType: "bytes4", type: "bytes4" }],
    name: "supportsInterface",
    outputs: [{ name: "", internalType: "bool", type: "bool" }],
  },
  {
    stateMutability: "payable",
    type: "function",
    inputs: [{ name: "newOwner", internalType: "address", type: "address" }],
    name: "transferOwnership",
    outputs: [],
  },
  {
    stateMutability: "nonpayable",
    type: "function",
    inputs: [{ name: "_baseFee", internalType: "uint256", type: "uint256" }],
    name: "updateBaseFee",
    outputs: [],
  },
  {
    stateMutability: "nonpayable",
    type: "function",
    inputs: [{ name: "_percentFee", internalType: "uint256", type: "uint256" }],
    name: "updatePercentFee",
    outputs: [],
  },
  {
    stateMutability: "nonpayable",
    type: "function",
    inputs: [
      { name: "_poolId", internalType: "uint256", type: "uint256" },
      {
        name: "_metadata",
        internalType: "struct Metadata",
        type: "tuple",
        components: [
          { name: "protocol", internalType: "uint256", type: "uint256" },
          { name: "pointer", internalType: "string", type: "string" },
        ],
      },
    ],
    name: "updatePoolMetadata",
    outputs: [],
  },
  {
    stateMutability: "nonpayable",
    type: "function",
    inputs: [{ name: "_registry", internalType: "address", type: "address" }],
    name: "updateRegistry",
    outputs: [],
  },
  {
    stateMutability: "nonpayable",
    type: "function",
    inputs: [
      { name: "_treasury", internalType: "address payable", type: "address" },
    ],
    name: "updateTreasury",
    outputs: [],
  },
  {
    type: "event",
    anonymous: false,
    inputs: [
      {
        name: "poolId",
        internalType: "uint256",
        type: "uint256",
        indexed: true,
      },
      {
        name: "amount",
        internalType: "uint256",
        type: "uint256",
        indexed: false,
      },
    ],
    name: "BaseFeePaid",
  },
  {
    type: "event",
    anonymous: false,
    inputs: [
      {
        name: "baseFee",
        internalType: "uint256",
        type: "uint256",
        indexed: false,
      },
    ],
    name: "BaseFeeUpdated",
  },
  {
    type: "event",
    anonymous: false,
    inputs: [
      { name: "version", internalType: "uint8", type: "uint8", indexed: false },
    ],
    name: "Initialized",
  },
  {
    type: "event",
    anonymous: false,
    inputs: [
      {
        name: "pendingOwner",
        internalType: "address",
        type: "address",
        indexed: true,
      },
    ],
    name: "OwnershipHandoverCanceled",
  },
  {
    type: "event",
    anonymous: false,
    inputs: [
      {
        name: "pendingOwner",
        internalType: "address",
        type: "address",
        indexed: true,
      },
    ],
    name: "OwnershipHandoverRequested",
  },
  {
    type: "event",
    anonymous: false,
    inputs: [
      {
        name: "oldOwner",
        internalType: "address",
        type: "address",
        indexed: true,
      },
      {
        name: "newOwner",
        internalType: "address",
        type: "address",
        indexed: true,
      },
    ],
    name: "OwnershipTransferred",
  },
  {
    type: "event",
    anonymous: false,
    inputs: [
      {
        name: "percentFee",
        internalType: "uint256",
        type: "uint256",
        indexed: false,
      },
    ],
    name: "PercentFeeUpdated",
  },
  {
    type: "event",
    anonymous: false,
    inputs: [
      {
        name: "poolId",
        internalType: "uint256",
        type: "uint256",
        indexed: true,
      },
      {
        name: "profileId",
        internalType: "bytes32",
        type: "bytes32",
        indexed: true,
      },
      {
        name: "strategy",
        internalType: "contract IStrategy",
        type: "address",
        indexed: false,
      },
      {
        name: "token",
        internalType: "address",
        type: "address",
        indexed: false,
      },
      {
        name: "amount",
        internalType: "uint256",
        type: "uint256",
        indexed: false,
      },
      {
        name: "metadata",
        internalType: "struct Metadata",
        type: "tuple",
        components: [
          { name: "protocol", internalType: "uint256", type: "uint256" },
          { name: "pointer", internalType: "string", type: "string" },
        ],
        indexed: false,
      },
    ],
    name: "PoolCreated",
  },
  {
    type: "event",
    anonymous: false,
    inputs: [
      {
        name: "poolId",
        internalType: "uint256",
        type: "uint256",
        indexed: true,
      },
      {
        name: "amount",
        internalType: "uint256",
        type: "uint256",
        indexed: false,
      },
      { name: "fee", internalType: "uint256", type: "uint256", indexed: false },
    ],
    name: "PoolFunded",
  },
  {
    type: "event",
    anonymous: false,
    inputs: [
      {
        name: "poolId",
        internalType: "uint256",
        type: "uint256",
        indexed: true,
      },
      {
        name: "metadata",
        internalType: "struct Metadata",
        type: "tuple",
        components: [
          { name: "protocol", internalType: "uint256", type: "uint256" },
          { name: "pointer", internalType: "string", type: "string" },
        ],
        indexed: false,
      },
    ],
    name: "PoolMetadataUpdated",
  },
  {
    type: "event",
    anonymous: false,
    inputs: [
      {
        name: "registry",
        internalType: "address",
        type: "address",
        indexed: false,
      },
    ],
    name: "RegistryUpdated",
  },
  {
    type: "event",
    anonymous: false,
    inputs: [
      { name: "role", internalType: "bytes32", type: "bytes32", indexed: true },
      {
        name: "previousAdminRole",
        internalType: "bytes32",
        type: "bytes32",
        indexed: true,
      },
      {
        name: "newAdminRole",
        internalType: "bytes32",
        type: "bytes32",
        indexed: true,
      },
    ],
    name: "RoleAdminChanged",
  },
  {
    type: "event",
    anonymous: false,
    inputs: [
      { name: "role", internalType: "bytes32", type: "bytes32", indexed: true },
      {
        name: "account",
        internalType: "address",
        type: "address",
        indexed: true,
      },
      {
        name: "sender",
        internalType: "address",
        type: "address",
        indexed: true,
      },
    ],
    name: "RoleGranted",
  },
  {
    type: "event",
    anonymous: false,
    inputs: [
      { name: "role", internalType: "bytes32", type: "bytes32", indexed: true },
      {
        name: "account",
        internalType: "address",
        type: "address",
        indexed: true,
      },
      {
        name: "sender",
        internalType: "address",
        type: "address",
        indexed: true,
      },
    ],
    name: "RoleRevoked",
  },
  {
    type: "event",
    anonymous: false,
    inputs: [
      {
        name: "strategy",
        internalType: "address",
        type: "address",
        indexed: false,
      },
    ],
    name: "StrategyApproved",
  },
  {
    type: "event",
    anonymous: false,
    inputs: [
      {
        name: "strategy",
        internalType: "address",
        type: "address",
        indexed: false,
      },
    ],
    name: "StrategyRemoved",
  },
  {
    type: "event",
    anonymous: false,
    inputs: [
      {
        name: "treasury",
        internalType: "address",
        type: "address",
        indexed: false,
      },
    ],
    name: "TreasuryUpdated",
  },
  { type: "error", inputs: [], name: "ALLOCATION_ACTIVE" },
  { type: "error", inputs: [], name: "ALLOCATION_NOT_ACTIVE" },
  { type: "error", inputs: [], name: "ALLOCATION_NOT_ENDED" },
  { type: "error", inputs: [], name: "ALREADY_INITIALIZED" },
  { type: "error", inputs: [], name: "AMOUNT_MISMATCH" },
  { type: "error", inputs: [], name: "ANCHOR_ERROR" },
  { type: "error", inputs: [], name: "ARRAY_MISMATCH" },
  { type: "error", inputs: [], name: "INVALID" },
  { type: "error", inputs: [], name: "INVALID_ADDRESS" },
  { type: "error", inputs: [], name: "INVALID_FEE" },
  { type: "error", inputs: [], name: "INVALID_METADATA" },
  { type: "error", inputs: [], name: "INVALID_REGISTRATION" },
  { type: "error", inputs: [], name: "IS_APPROVED_STRATEGY" },
  { type: "error", inputs: [], name: "MISMATCH" },
  { type: "error", inputs: [], name: "NONCE_NOT_AVAILABLE" },
  { type: "error", inputs: [], name: "NOT_APPROVED_STRATEGY" },
  { type: "error", inputs: [], name: "NOT_ENOUGH_FUNDS" },
  { type: "error", inputs: [], name: "NOT_IMPLEMENTED" },
  { type: "error", inputs: [], name: "NOT_INITIALIZED" },
  { type: "error", inputs: [], name: "NOT_PENDING_OWNER" },
  { type: "error", inputs: [], name: "NewOwnerIsZeroAddress" },
  { type: "error", inputs: [], name: "NoHandoverRequest" },
  { type: "error", inputs: [], name: "POOL_ACTIVE" },
  { type: "error", inputs: [], name: "POOL_INACTIVE" },
  { type: "error", inputs: [], name: "RECIPIENT_ALREADY_ACCEPTED" },
  {
    type: "error",
    inputs: [{ name: "recipientId", internalType: "address", type: "address" }],
    name: "RECIPIENT_ERROR",
  },
  { type: "error", inputs: [], name: "RECIPIENT_NOT_ACCEPTED" },
  { type: "error", inputs: [], name: "REGISTRATION_NOT_ACTIVE" },
  { type: "error", inputs: [], name: "UNAUTHORIZED" },
  { type: "error", inputs: [], name: "Unauthorized" },
  { type: "error", inputs: [], name: "ZERO_ADDRESS" },
] as const;

//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// CVStrategy
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

export const cvStrategyABI = [
  { stateMutability: "payable", type: "fallback" },
  { stateMutability: "payable", type: "receive" },
  {
    stateMutability: "view",
    type: "function",
    inputs: [],
    name: "D",
    outputs: [{ name: "", internalType: "uint256", type: "uint256" }],
  },
  {
    stateMutability: "view",
    type: "function",
    inputs: [],
    name: "DISPUTE_COOLDOWN_SEC",
    outputs: [{ name: "", internalType: "uint256", type: "uint256" }],
  },
  {
    stateMutability: "view",
    type: "function",
    inputs: [],
    name: "MAX_STAKED_PROPOSALS",
    outputs: [{ name: "", internalType: "uint256", type: "uint256" }],
  },
  {
    stateMutability: "view",
    type: "function",
    inputs: [],
    name: "NATIVE",
    outputs: [{ name: "", internalType: "address", type: "address" }],
  },
  {
    stateMutability: "view",
    type: "function",
    inputs: [],
    name: "RULING_OPTIONS",
    outputs: [{ name: "", internalType: "uint256", type: "uint256" }],
  },
  {
    stateMutability: "view",
    type: "function",
    inputs: [],
    name: "VERSION",
    outputs: [{ name: "", internalType: "string", type: "string" }],
  },
  {
    stateMutability: "nonpayable",
    type: "function",
    inputs: [],
    name: "activatePoints",
    outputs: [],
  },
  {
    stateMutability: "payable",
    type: "function",
    inputs: [
      { name: "_data", internalType: "bytes", type: "bytes" },
      { name: "_sender", internalType: "address", type: "address" },
    ],
    name: "allocate",
    outputs: [],
  },
  {
    stateMutability: "view",
    type: "function",
    inputs: [{ name: "", internalType: "uint256", type: "uint256" }],
    name: "arbitrableConfigs",
    outputs: [
      {
        name: "arbitrator",
        internalType: "contract IArbitrator",
        type: "address",
      },
      { name: "tribunalSafe", internalType: "address", type: "address" },
      {
        name: "submitterCollateralAmount",
        internalType: "uint256",
        type: "uint256",
      },
      {
        name: "challengerCollateralAmount",
        internalType: "uint256",
        type: "uint256",
      },
      { name: "defaultRuling", internalType: "uint256", type: "uint256" },
      {
        name: "defaultRulingTimeout",
        internalType: "uint256",
        type: "uint256",
      },
    ],
  },
  {
    stateMutability: "view",
    type: "function",
    inputs: [
      { name: "_timePassed", internalType: "uint256", type: "uint256" },
      { name: "_lastConv", internalType: "uint256", type: "uint256" },
      { name: "_oldAmount", internalType: "uint256", type: "uint256" },
    ],
    name: "calculateConviction",
    outputs: [{ name: "", internalType: "uint256", type: "uint256" }],
  },
  {
    stateMutability: "view",
    type: "function",
    inputs: [
      { name: "_requestedAmount", internalType: "uint256", type: "uint256" },
    ],
    name: "calculateThreshold",
    outputs: [{ name: "_threshold", internalType: "uint256", type: "uint256" }],
  },
  {
    stateMutability: "view",
    type: "function",
    inputs: [{ name: "proposalId", internalType: "uint256", type: "uint256" }],
    name: "canExecuteProposal",
    outputs: [{ name: "canBeExecuted", internalType: "bool", type: "bool" }],
  },
  {
    stateMutability: "nonpayable",
    type: "function",
    inputs: [{ name: "proposalId", internalType: "uint256", type: "uint256" }],
    name: "cancelProposal",
    outputs: [],
  },
  {
    stateMutability: "view",
    type: "function",
    inputs: [],
    name: "cloneNonce",
    outputs: [{ name: "", internalType: "uint256", type: "uint256" }],
  },
  {
    stateMutability: "view",
    type: "function",
    inputs: [],
    name: "collateralVault",
    outputs: [
      { name: "", internalType: "contract ICollateralVault", type: "address" },
    ],
  },
  {
    stateMutability: "view",
    type: "function",
    inputs: [],
    name: "currentArbitrableConfigVersion",
    outputs: [{ name: "", internalType: "uint256", type: "uint256" }],
  },
  {
    stateMutability: "view",
    type: "function",
    inputs: [],
    name: "cvParams",
    outputs: [
      { name: "maxRatio", internalType: "uint256", type: "uint256" },
      { name: "weight", internalType: "uint256", type: "uint256" },
      { name: "decay", internalType: "uint256", type: "uint256" },
      { name: "minThresholdPoints", internalType: "uint256", type: "uint256" },
    ],
  },
  {
    stateMutability: "nonpayable",
    type: "function",
    inputs: [],
    name: "deactivatePoints",
    outputs: [],
  },
  {
    stateMutability: "nonpayable",
    type: "function",
    inputs: [{ name: "_member", internalType: "address", type: "address" }],
    name: "deactivatePoints",
    outputs: [],
  },
  {
    stateMutability: "nonpayable",
    type: "function",
    inputs: [
      { name: "_member", internalType: "address", type: "address" },
      { name: "_amountToUnstake", internalType: "uint256", type: "uint256" },
    ],
    name: "decreasePower",
    outputs: [{ name: "", internalType: "uint256", type: "uint256" }],
  },
  {
    stateMutability: "view",
    type: "function",
    inputs: [],
    name: "disputeCount",
    outputs: [{ name: "", internalType: "uint64", type: "uint64" }],
  },
  {
    stateMutability: "view",
    type: "function",
    inputs: [{ name: "", internalType: "uint256", type: "uint256" }],
    name: "disputeIdToProposalId",
    outputs: [{ name: "", internalType: "uint256", type: "uint256" }],
  },
  {
    stateMutability: "payable",
    type: "function",
    inputs: [
      { name: "proposalId", internalType: "uint256", type: "uint256" },
      { name: "context", internalType: "string", type: "string" },
      { name: "_extraData", internalType: "bytes", type: "bytes" },
    ],
    name: "disputeProposal",
    outputs: [{ name: "disputeId", internalType: "uint256", type: "uint256" }],
  },
  {
    stateMutability: "nonpayable",
    type: "function",
    inputs: [
      { name: "_recipientIds", internalType: "address[]", type: "address[]" },
      { name: "_data", internalType: "bytes", type: "bytes" },
      { name: "_sender", internalType: "address", type: "address" },
    ],
    name: "distribute",
    outputs: [],
  },
  {
    stateMutability: "view",
    type: "function",
    inputs: [],
    name: "getAllo",
    outputs: [{ name: "", internalType: "contract IAllo", type: "address" }],
  },
  {
    stateMutability: "view",
    type: "function",
    inputs: [],
    name: "getArbitrableConfig",
    outputs: [
      {
        name: "arbitrator",
        internalType: "contract IArbitrator",
        type: "address",
      },
      { name: "tribunalSafe", internalType: "address", type: "address" },
      {
        name: "submitterCollateralAmount",
        internalType: "uint256",
        type: "uint256",
      },
      {
        name: "challengerCollateralAmount",
        internalType: "uint256",
        type: "uint256",
      },
      { name: "defaultRuling", internalType: "uint256", type: "uint256" },
      {
        name: "defaultRulingTimeout",
        internalType: "uint256",
        type: "uint256",
      },
    ],
  },
  {
    stateMutability: "view",
    type: "function",
    inputs: [],
    name: "getDecay",
    outputs: [{ name: "", internalType: "uint256", type: "uint256" }],
  },
  {
    stateMutability: "view",
    type: "function",
    inputs: [],
    name: "getMaxAmount",
    outputs: [{ name: "", internalType: "uint256", type: "uint256" }],
  },
  {
    stateMutability: "view",
    type: "function",
    inputs: [{ name: "amount", internalType: "uint256", type: "uint256" }],
    name: "getMaxConviction",
    outputs: [{ name: "", internalType: "uint256", type: "uint256" }],
  },
  {
    stateMutability: "view",
    type: "function",
    inputs: [{ name: "_proposalId", internalType: "uint256", type: "uint256" }],
    name: "getMetadata",
    outputs: [
      {
        name: "",
        internalType: "struct Metadata",
        type: "tuple",
        components: [
          { name: "protocol", internalType: "uint256", type: "uint256" },
          { name: "pointer", internalType: "string", type: "string" },
        ],
      },
    ],
  },
  {
    stateMutability: "pure",
    type: "function",
    inputs: [
      { name: "", internalType: "address[]", type: "address[]" },
      { name: "", internalType: "bytes[]", type: "bytes[]" },
    ],
    name: "getPayouts",
    outputs: [
      {
        name: "",
        internalType: "struct IStrategy.PayoutSummary[]",
        type: "tuple[]",
        components: [
          {
            name: "recipientAddress",
            internalType: "address",
            type: "address",
          },
          { name: "amount", internalType: "uint256", type: "uint256" },
        ],
      },
    ],
  },
  {
    stateMutability: "view",
    type: "function",
    inputs: [],
    name: "getPointSystem",
    outputs: [
      {
        name: "",
        internalType: "enum StrategyStruct.PointSystem",
        type: "uint8",
      },
    ],
  },
  {
    stateMutability: "view",
    type: "function",
    inputs: [],
    name: "getPoolAmount",
    outputs: [{ name: "", internalType: "uint256", type: "uint256" }],
  },
  {
    stateMutability: "view",
    type: "function",
    inputs: [],
    name: "getPoolId",
    outputs: [{ name: "", internalType: "uint256", type: "uint256" }],
  },
  {
    stateMutability: "view",
    type: "function",
    inputs: [{ name: "_proposalId", internalType: "uint256", type: "uint256" }],
    name: "getProposal",
    outputs: [
      { name: "submitter", internalType: "address", type: "address" },
      { name: "beneficiary", internalType: "address", type: "address" },
      { name: "requestedToken", internalType: "address", type: "address" },
      { name: "requestedAmount", internalType: "uint256", type: "uint256" },
      { name: "stakedAmount", internalType: "uint256", type: "uint256" },
      {
        name: "proposalStatus",
        internalType: "enum StrategyStruct.ProposalStatus",
        type: "uint8",
      },
      { name: "blockLast", internalType: "uint256", type: "uint256" },
      { name: "convictionLast", internalType: "uint256", type: "uint256" },
      { name: "threshold", internalType: "uint256", type: "uint256" },
      { name: "voterStakedPoints", internalType: "uint256", type: "uint256" },
      {
        name: "arbitrableConfigVersion",
        internalType: "uint256",
        type: "uint256",
      },
    ],
  },
  {
    stateMutability: "view",
    type: "function",
    inputs: [{ name: "_proposalId", internalType: "uint256", type: "uint256" }],
    name: "getProposalStakedAmount",
    outputs: [{ name: "", internalType: "uint256", type: "uint256" }],
  },
  {
    stateMutability: "view",
    type: "function",
    inputs: [
      { name: "_proposalId", internalType: "uint256", type: "uint256" },
      { name: "_voter", internalType: "address", type: "address" },
    ],
    name: "getProposalVoterStake",
    outputs: [{ name: "", internalType: "uint256", type: "uint256" }],
  },
  {
    stateMutability: "view",
    type: "function",
    inputs: [
      { name: "_recipientId", internalType: "address", type: "address" },
    ],
    name: "getRecipientStatus",
    outputs: [
      { name: "", internalType: "enum IStrategy.Status", type: "uint8" },
    ],
  },
  {
    stateMutability: "view",
    type: "function",
    inputs: [],
    name: "getStrategyId",
    outputs: [{ name: "", internalType: "bytes32", type: "bytes32" }],
  },
  {
    stateMutability: "view",
    type: "function",
    inputs: [{ name: "_voter", internalType: "address", type: "address" }],
    name: "getTotalVoterStakePct",
    outputs: [{ name: "", internalType: "uint256", type: "uint256" }],
  },
  {
    stateMutability: "nonpayable",
    type: "function",
    inputs: [{ name: "_amount", internalType: "uint256", type: "uint256" }],
    name: "increasePoolAmount",
    outputs: [],
  },
  {
    stateMutability: "nonpayable",
    type: "function",
    inputs: [
      { name: "_member", internalType: "address", type: "address" },
      { name: "_amountToStake", internalType: "uint256", type: "uint256" },
    ],
    name: "increasePower",
    outputs: [{ name: "", internalType: "uint256", type: "uint256" }],
  },
  {
    stateMutability: "nonpayable",
    type: "function",
    inputs: [
      { name: "_allo", internalType: "address", type: "address" },
      {
        name: "_collateralVaultTemplate",
        internalType: "address",
        type: "address",
      },
      { name: "owner", internalType: "address", type: "address" },
    ],
    name: "init",
    outputs: [],
  },
  {
    stateMutability: "nonpayable",
    type: "function",
    inputs: [
      { name: "_allo", internalType: "address", type: "address" },
      { name: "_name", internalType: "string", type: "string" },
      { name: "owner", internalType: "address", type: "address" },
    ],
    name: "init",
    outputs: [],
  },
  {
    stateMutability: "nonpayable",
    type: "function",
    inputs: [
      { name: "initialOwner", internalType: "address", type: "address" },
    ],
    name: "initialize",
    outputs: [],
  },
  {
    stateMutability: "nonpayable",
    type: "function",
    inputs: [
      { name: "_poolId", internalType: "uint256", type: "uint256" },
      { name: "_data", internalType: "bytes", type: "bytes" },
    ],
    name: "initialize",
    outputs: [],
  },
  {
    stateMutability: "view",
    type: "function",
    inputs: [],
    name: "isPoolActive",
    outputs: [{ name: "", internalType: "bool", type: "bool" }],
  },
  {
    stateMutability: "view",
    type: "function",
    inputs: [{ name: "_allocator", internalType: "address", type: "address" }],
    name: "isValidAllocator",
    outputs: [{ name: "", internalType: "bool", type: "bool" }],
  },
  {
    stateMutability: "view",
    type: "function",
    inputs: [],
    name: "owner",
    outputs: [{ name: "", internalType: "address", type: "address" }],
  },
  {
    stateMutability: "view",
    type: "function",
    inputs: [],
    name: "pointConfig",
    outputs: [{ name: "maxAmount", internalType: "uint256", type: "uint256" }],
  },
  {
    stateMutability: "view",
    type: "function",
    inputs: [],
    name: "pointSystem",
    outputs: [
      {
        name: "",
        internalType: "enum StrategyStruct.PointSystem",
        type: "uint8",
      },
    ],
  },
  {
    stateMutability: "view",
    type: "function",
    inputs: [],
    name: "proposalCounter",
    outputs: [{ name: "", internalType: "uint256", type: "uint256" }],
  },
  {
    stateMutability: "view",
    type: "function",
    inputs: [],
    name: "proposalType",
    outputs: [
      {
        name: "",
        internalType: "enum StrategyStruct.ProposalType",
        type: "uint8",
      },
    ],
  },
  {
    stateMutability: "view",
    type: "function",
    inputs: [{ name: "", internalType: "uint256", type: "uint256" }],
    name: "proposals",
    outputs: [
      { name: "proposalId", internalType: "uint256", type: "uint256" },
      { name: "requestedAmount", internalType: "uint256", type: "uint256" },
      { name: "stakedAmount", internalType: "uint256", type: "uint256" },
      { name: "convictionLast", internalType: "uint256", type: "uint256" },
      { name: "beneficiary", internalType: "address", type: "address" },
      { name: "submitter", internalType: "address", type: "address" },
      { name: "requestedToken", internalType: "address", type: "address" },
      { name: "blockLast", internalType: "uint256", type: "uint256" },
      {
        name: "proposalStatus",
        internalType: "enum StrategyStruct.ProposalStatus",
        type: "uint8",
      },
      {
        name: "metadata",
        internalType: "struct Metadata",
        type: "tuple",
        components: [
          { name: "protocol", internalType: "uint256", type: "uint256" },
          { name: "pointer", internalType: "string", type: "string" },
        ],
      },
      {
        name: "disputeInfo",
        internalType: "struct StrategyStruct.ProposalDisputeInfo",
        type: "tuple",
        components: [
          { name: "disputeId", internalType: "uint256", type: "uint256" },
          {
            name: "disputeTimestamp",
            internalType: "uint256",
            type: "uint256",
          },
          { name: "challenger", internalType: "address", type: "address" },
        ],
      },
      {
        name: "lastDisputeCompletion",
        internalType: "uint256",
        type: "uint256",
      },
      {
        name: "arbitrableConfigVersion",
        internalType: "uint256",
        type: "uint256",
      },
    ],
  },
  {
    stateMutability: "view",
    type: "function",
    inputs: [],
    name: "proxiableUUID",
    outputs: [{ name: "", internalType: "bytes32", type: "bytes32" }],
  },
  {
    stateMutability: "payable",
    type: "function",
    inputs: [
      { name: "_data", internalType: "bytes", type: "bytes" },
      { name: "_sender", internalType: "address", type: "address" },
    ],
    name: "registerRecipient",
    outputs: [
      { name: "recipientId", internalType: "address", type: "address" },
    ],
  },
  {
    stateMutability: "view",
    type: "function",
    inputs: [],
    name: "registryCommunity",
    outputs: [
      {
        name: "",
        internalType: "contract RegistryCommunityV0_0",
        type: "address",
      },
    ],
  },
  {
    stateMutability: "nonpayable",
    type: "function",
    inputs: [],
    name: "renounceOwnership",
    outputs: [],
  },
  {
    stateMutability: "nonpayable",
    type: "function",
    inputs: [
      { name: "_disputeID", internalType: "uint256", type: "uint256" },
      { name: "_ruling", internalType: "uint256", type: "uint256" },
    ],
    name: "rule",
    outputs: [],
  },
  {
    stateMutability: "nonpayable",
    type: "function",
    inputs: [{ name: "template", internalType: "address", type: "address" }],
    name: "setCollateralVaultTemplate",
    outputs: [],
  },
  {
    stateMutability: "nonpayable",
    type: "function",
    inputs: [{ name: "_active", internalType: "bool", type: "bool" }],
    name: "setPoolActive",
    outputs: [],
  },
  {
    stateMutability: "nonpayable",
    type: "function",
    inputs: [
      {
        name: "_arbitrableConfig",
        internalType: "struct StrategyStruct.ArbitrableConfig",
        type: "tuple",
        components: [
          {
            name: "arbitrator",
            internalType: "contract IArbitrator",
            type: "address",
          },
          { name: "tribunalSafe", internalType: "address", type: "address" },
          {
            name: "submitterCollateralAmount",
            internalType: "uint256",
            type: "uint256",
          },
          {
            name: "challengerCollateralAmount",
            internalType: "uint256",
            type: "uint256",
          },
          { name: "defaultRuling", internalType: "uint256", type: "uint256" },
          {
            name: "defaultRulingTimeout",
            internalType: "uint256",
            type: "uint256",
          },
        ],
      },
      {
        name: "_cvParams",
        internalType: "struct StrategyStruct.CVParams",
        type: "tuple",
        components: [
          { name: "maxRatio", internalType: "uint256", type: "uint256" },
          { name: "weight", internalType: "uint256", type: "uint256" },
          { name: "decay", internalType: "uint256", type: "uint256" },
          {
            name: "minThresholdPoints",
            internalType: "uint256",
            type: "uint256",
          },
        ],
      },
    ],
    name: "setPoolParams",
    outputs: [],
  },
  {
    stateMutability: "nonpayable",
    type: "function",
    inputs: [
      { name: "_sybilScorer", internalType: "address", type: "address" },
    ],
    name: "setSybilScorer",
    outputs: [],
  },
  {
    stateMutability: "view",
    type: "function",
    inputs: [{ name: "interfaceId", internalType: "bytes4", type: "bytes4" }],
    name: "supportsInterface",
    outputs: [{ name: "", internalType: "bool", type: "bool" }],
  },
  {
    stateMutability: "view",
    type: "function",
    inputs: [],
    name: "sybilScorer",
    outputs: [
      { name: "", internalType: "contract ISybilScorer", type: "address" },
    ],
  },
  {
    stateMutability: "view",
    type: "function",
    inputs: [],
    name: "totalEffectiveActivePoints",
    outputs: [{ name: "", internalType: "uint256", type: "uint256" }],
  },
  {
    stateMutability: "view",
    type: "function",
    inputs: [],
    name: "totalPointsActivated",
    outputs: [{ name: "", internalType: "uint256", type: "uint256" }],
  },
  {
    stateMutability: "view",
    type: "function",
    inputs: [],
    name: "totalStaked",
    outputs: [{ name: "", internalType: "uint256", type: "uint256" }],
  },
  {
    stateMutability: "view",
    type: "function",
    inputs: [{ name: "", internalType: "address", type: "address" }],
    name: "totalVoterStakePct",
    outputs: [{ name: "", internalType: "uint256", type: "uint256" }],
  },
  {
    stateMutability: "nonpayable",
    type: "function",
    inputs: [{ name: "newOwner", internalType: "address", type: "address" }],
    name: "transferOwnership",
    outputs: [],
  },
  {
    stateMutability: "nonpayable",
    type: "function",
    inputs: [{ name: "proposalId", internalType: "uint256", type: "uint256" }],
    name: "updateProposalConviction",
    outputs: [{ name: "", internalType: "uint256", type: "uint256" }],
  },
  {
    stateMutability: "nonpayable",
    type: "function",
    inputs: [
      { name: "newImplementation", internalType: "address", type: "address" },
    ],
    name: "upgradeTo",
    outputs: [],
  },
  {
    stateMutability: "payable",
    type: "function",
    inputs: [
      { name: "newImplementation", internalType: "address", type: "address" },
      { name: "data", internalType: "bytes", type: "bytes" },
    ],
    name: "upgradeToAndCall",
    outputs: [],
  },
  {
    stateMutability: "view",
    type: "function",
    inputs: [
      { name: "", internalType: "address", type: "address" },
      { name: "", internalType: "uint256", type: "uint256" },
    ],
    name: "voterStakedProposals",
    outputs: [{ name: "", internalType: "uint256", type: "uint256" }],
  },
  {
    type: "event",
    anonymous: false,
    inputs: [
      {
        name: "previousAdmin",
        internalType: "address",
        type: "address",
        indexed: false,
      },
      {
        name: "newAdmin",
        internalType: "address",
        type: "address",
        indexed: false,
      },
    ],
    name: "AdminChanged",
  },
  {
    type: "event",
    anonymous: false,
    inputs: [
      {
        name: "recipientId",
        internalType: "address",
        type: "address",
        indexed: true,
      },
      {
        name: "amount",
        internalType: "uint256",
        type: "uint256",
        indexed: false,
      },
      {
        name: "token",
        internalType: "address",
        type: "address",
        indexed: false,
      },
      {
        name: "sender",
        internalType: "address",
        type: "address",
        indexed: false,
      },
    ],
    name: "Allocated",
  },
  {
    type: "event",
    anonymous: false,
    inputs: [
      {
        name: "currentArbitrableConfigVersion",
        internalType: "uint256",
        type: "uint256",
        indexed: false,
      },
      {
        name: "arbitrator",
        internalType: "contract IArbitrator",
        type: "address",
        indexed: false,
      },
      {
        name: "tribunalSafe",
        internalType: "address",
        type: "address",
        indexed: false,
      },
      {
        name: "submitterCollateralAmount",
        internalType: "uint256",
        type: "uint256",
        indexed: false,
      },
      {
        name: "challengerCollateralAmount",
        internalType: "uint256",
        type: "uint256",
        indexed: false,
      },
      {
        name: "defaultRuling",
        internalType: "uint256",
        type: "uint256",
        indexed: false,
      },
      {
        name: "defaultRulingTimeout",
        internalType: "uint256",
        type: "uint256",
        indexed: false,
      },
    ],
    name: "ArbitrableConfigUpdated",
  },
  {
    type: "event",
    anonymous: false,
    inputs: [
      {
        name: "beacon",
        internalType: "address",
        type: "address",
        indexed: true,
      },
    ],
    name: "BeaconUpgraded",
  },
  {
    type: "event",
    anonymous: false,
    inputs: [
      {
        name: "cvParams",
        internalType: "struct StrategyStruct.CVParams",
        type: "tuple",
        components: [
          { name: "maxRatio", internalType: "uint256", type: "uint256" },
          { name: "weight", internalType: "uint256", type: "uint256" },
          { name: "decay", internalType: "uint256", type: "uint256" },
          {
            name: "minThresholdPoints",
            internalType: "uint256",
            type: "uint256",
          },
        ],
        indexed: false,
      },
    ],
    name: "CVParamsUpdated",
  },
  {
    type: "event",
    anonymous: false,
    inputs: [
      {
        name: "_arbitrator",
        internalType: "contract IArbitrator",
        type: "address",
        indexed: true,
      },
      {
        name: "_arbitrableDisputeID",
        internalType: "uint256",
        type: "uint256",
        indexed: true,
      },
      {
        name: "_externalDisputeID",
        internalType: "uint256",
        type: "uint256",
        indexed: false,
      },
      {
        name: "_templateId",
        internalType: "uint256",
        type: "uint256",
        indexed: false,
      },
      {
        name: "_templateUri",
        internalType: "string",
        type: "string",
        indexed: false,
      },
    ],
    name: "DisputeRequest",
  },
  {
    type: "event",
    anonymous: false,
    inputs: [
      {
        name: "proposalId",
        internalType: "uint256",
        type: "uint256",
        indexed: false,
      },
      {
        name: "beneficiary",
        internalType: "address",
        type: "address",
        indexed: false,
      },
      {
        name: "amount",
        internalType: "uint256",
        type: "uint256",
        indexed: false,
      },
    ],
    name: "Distributed",
  },
  {
    type: "event",
    anonymous: false,
    inputs: [
      {
        name: "recipientId",
        internalType: "address",
        type: "address",
        indexed: true,
      },
      {
        name: "recipientAddress",
        internalType: "address",
        type: "address",
        indexed: false,
      },
      {
        name: "amount",
        internalType: "uint256",
        type: "uint256",
        indexed: false,
      },
      {
        name: "sender",
        internalType: "address",
        type: "address",
        indexed: false,
      },
    ],
    name: "Distributed",
  },
  {
    type: "event",
    anonymous: false,
    inputs: [
      {
        name: "poolId",
        internalType: "uint256",
        type: "uint256",
        indexed: false,
      },
      { name: "data", internalType: "bytes", type: "bytes", indexed: false },
    ],
    name: "Initialized",
  },
  {
    type: "event",
    anonymous: false,
    inputs: [
      { name: "version", internalType: "uint8", type: "uint8", indexed: false },
    ],
    name: "Initialized",
  },
  {
    type: "event",
    anonymous: false,
    inputs: [
      {
        name: "poolId",
        internalType: "uint256",
        type: "uint256",
        indexed: false,
      },
      {
        name: "data",
        internalType: "struct StrategyStruct.InitializeParams",
        type: "tuple",
        components: [
          {
            name: "cvParams",
            internalType: "struct StrategyStruct.CVParams",
            type: "tuple",
            components: [
              { name: "maxRatio", internalType: "uint256", type: "uint256" },
              { name: "weight", internalType: "uint256", type: "uint256" },
              { name: "decay", internalType: "uint256", type: "uint256" },
              {
                name: "minThresholdPoints",
                internalType: "uint256",
                type: "uint256",
              },
            ],
          },
          {
            name: "proposalType",
            internalType: "enum StrategyStruct.ProposalType",
            type: "uint8",
          },
          {
            name: "pointSystem",
            internalType: "enum StrategyStruct.PointSystem",
            type: "uint8",
          },
          {
            name: "pointConfig",
            internalType: "struct StrategyStruct.PointSystemConfig",
            type: "tuple",
            components: [
              { name: "maxAmount", internalType: "uint256", type: "uint256" },
            ],
          },
          {
            name: "arbitrableConfig",
            internalType: "struct StrategyStruct.ArbitrableConfig",
            type: "tuple",
            components: [
              {
                name: "arbitrator",
                internalType: "contract IArbitrator",
                type: "address",
              },
              {
                name: "tribunalSafe",
                internalType: "address",
                type: "address",
              },
              {
                name: "submitterCollateralAmount",
                internalType: "uint256",
                type: "uint256",
              },
              {
                name: "challengerCollateralAmount",
                internalType: "uint256",
                type: "uint256",
              },
              {
                name: "defaultRuling",
                internalType: "uint256",
                type: "uint256",
              },
              {
                name: "defaultRulingTimeout",
                internalType: "uint256",
                type: "uint256",
              },
            ],
          },
          {
            name: "registryCommunity",
            internalType: "address",
            type: "address",
          },
          { name: "sybilScorer", internalType: "address", type: "address" },
        ],
        indexed: false,
      },
    ],
    name: "InitializedCV",
  },
  {
    type: "event",
    anonymous: false,
    inputs: [
      {
        name: "before",
        internalType: "uint256",
        type: "uint256",
        indexed: false,
      },
      {
        name: "minThresholdPoints",
        internalType: "uint256",
        type: "uint256",
        indexed: false,
      },
    ],
    name: "MinThresholdPointsUpdated",
  },
  {
    type: "event",
    anonymous: false,
    inputs: [
      {
        name: "previousOwner",
        internalType: "address",
        type: "address",
        indexed: true,
      },
      {
        name: "newOwner",
        internalType: "address",
        type: "address",
        indexed: true,
      },
    ],
    name: "OwnershipTransferred",
  },
  {
    type: "event",
    anonymous: false,
    inputs: [
      {
        name: "member",
        internalType: "address",
        type: "address",
        indexed: false,
      },
    ],
    name: "PointsDeactivated",
  },
  {
    type: "event",
    anonymous: false,
    inputs: [
      { name: "active", internalType: "bool", type: "bool", indexed: false },
    ],
    name: "PoolActive",
  },
  {
    type: "event",
    anonymous: false,
    inputs: [
      {
        name: "amount",
        internalType: "uint256",
        type: "uint256",
        indexed: false,
      },
    ],
    name: "PoolAmountIncreased",
  },
  {
    type: "event",
    anonymous: false,
    inputs: [
      {
        name: "member",
        internalType: "address",
        type: "address",
        indexed: false,
      },
      {
        name: "tokensUnStaked",
        internalType: "uint256",
        type: "uint256",
        indexed: false,
      },
      {
        name: "pointsToDecrease",
        internalType: "uint256",
        type: "uint256",
        indexed: false,
      },
    ],
    name: "PowerDecreased",
  },
  {
    type: "event",
    anonymous: false,
    inputs: [
      {
        name: "member",
        internalType: "address",
        type: "address",
        indexed: false,
      },
      {
        name: "tokensStaked",
        internalType: "uint256",
        type: "uint256",
        indexed: false,
      },
      {
        name: "pointsToIncrease",
        internalType: "uint256",
        type: "uint256",
        indexed: false,
      },
    ],
    name: "PowerIncreased",
  },
  {
    type: "event",
    anonymous: false,
    inputs: [
      {
        name: "proposalId",
        internalType: "uint256",
        type: "uint256",
        indexed: false,
      },
    ],
    name: "ProposalCancelled",
  },
  {
    type: "event",
    anonymous: false,
    inputs: [
      {
        name: "poolId",
        internalType: "uint256",
        type: "uint256",
        indexed: false,
      },
      {
        name: "proposalId",
        internalType: "uint256",
        type: "uint256",
        indexed: false,
      },
    ],
    name: "ProposalCreated",
  },
  {
    type: "event",
    anonymous: false,
    inputs: [
      {
        name: "arbitrator",
        internalType: "contract IArbitrator",
        type: "address",
        indexed: false,
      },
      {
        name: "proposalId",
        internalType: "uint256",
        type: "uint256",
        indexed: false,
      },
      {
        name: "disputeId",
        internalType: "uint256",
        type: "uint256",
        indexed: false,
      },
      {
        name: "challenger",
        internalType: "address",
        type: "address",
        indexed: false,
      },
      {
        name: "context",
        internalType: "string",
        type: "string",
        indexed: false,
      },
      {
        name: "timestamp",
        internalType: "uint256",
        type: "uint256",
        indexed: false,
      },
    ],
    name: "ProposalDisputed",
  },
  {
    type: "event",
    anonymous: false,
    inputs: [
      {
        name: "recipientId",
        internalType: "address",
        type: "address",
        indexed: true,
      },
      { name: "data", internalType: "bytes", type: "bytes", indexed: false },
      {
        name: "sender",
        internalType: "address",
        type: "address",
        indexed: false,
      },
    ],
    name: "Registered",
  },
  {
    type: "event",
    anonymous: false,
    inputs: [
      {
        name: "registryCommunity",
        internalType: "address",
        type: "address",
        indexed: false,
      },
    ],
    name: "RegistryUpdated",
  },
  {
    type: "event",
    anonymous: false,
    inputs: [
      {
        name: "_arbitrator",
        internalType: "contract IArbitrator",
        type: "address",
        indexed: true,
      },
      {
        name: "_disputeID",
        internalType: "uint256",
        type: "uint256",
        indexed: true,
      },
      {
        name: "_ruling",
        internalType: "uint256",
        type: "uint256",
        indexed: false,
      },
    ],
    name: "Ruling",
  },
  {
    type: "event",
    anonymous: false,
    inputs: [
      {
        name: "from",
        internalType: "address",
        type: "address",
        indexed: false,
      },
      {
        name: "proposalId",
        internalType: "uint256",
        type: "uint256",
        indexed: false,
      },
      {
        name: "amount",
        internalType: "uint256",
        type: "uint256",
        indexed: false,
      },
      {
        name: "totalStakedAmount",
        internalType: "uint256",
        type: "uint256",
        indexed: false,
      },
      {
        name: "convictionLast",
        internalType: "uint256",
        type: "uint256",
        indexed: false,
      },
    ],
    name: "SupportAdded",
  },
  {
    type: "event",
    anonymous: false,
    inputs: [
      {
        name: "strategy",
        internalType: "address",
        type: "address",
        indexed: false,
      },
      {
        name: "arbitrator",
        internalType: "address",
        type: "address",
        indexed: false,
      },
      {
        name: "tribunalSafe",
        internalType: "address",
        type: "address",
        indexed: false,
      },
    ],
    name: "TribunaSafeRegistered",
  },
  {
    type: "event",
    anonymous: false,
    inputs: [
      {
        name: "implementation",
        internalType: "address",
        type: "address",
        indexed: true,
      },
    ],
    name: "Upgraded",
  },
  { type: "error", inputs: [], name: "ALLOCATION_ACTIVE" },
  { type: "error", inputs: [], name: "ALLOCATION_NOT_ACTIVE" },
  { type: "error", inputs: [], name: "ALLOCATION_NOT_ENDED" },
  { type: "error", inputs: [], name: "ALREADY_INITIALIZED" },
  { type: "error", inputs: [], name: "AMOUNT_MISMATCH" },
  { type: "error", inputs: [], name: "ANCHOR_ERROR" },
  { type: "error", inputs: [], name: "ARRAY_MISMATCH" },
  { type: "error", inputs: [], name: "AddressCannotBeZero" },
  { type: "error", inputs: [], name: "AmountOverMaxRatio" },
  { type: "error", inputs: [], name: "ArbitratorCannotBeZero" },
  {
    type: "error",
    inputs: [
      { name: "_caller", internalType: "address", type: "address" },
      { name: "_owner", internalType: "address", type: "address" },
    ],
    name: "CallerNotOwner",
  },
  { type: "error", inputs: [], name: "CantIncreaseFixedSystem" },
  { type: "error", inputs: [], name: "ConvictionUnderMinimumThreshold" },
  { type: "error", inputs: [], name: "DefaultRulingNotSet" },
  {
    type: "error",
    inputs: [
      { name: "_proposalId", internalType: "uint256", type: "uint256" },
      { name: "_remainingSec", internalType: "uint256", type: "uint256" },
    ],
    name: "DisputeCooldownNotPassed",
  },
  { type: "error", inputs: [], name: "INVALID" },
  { type: "error", inputs: [], name: "INVALID_ADDRESS" },
  { type: "error", inputs: [], name: "INVALID_FEE" },
  { type: "error", inputs: [], name: "INVALID_METADATA" },
  { type: "error", inputs: [], name: "INVALID_REGISTRATION" },
  { type: "error", inputs: [], name: "IS_APPROVED_STRATEGY" },
  {
    type: "error",
    inputs: [
      { name: "sentAmount", internalType: "uint256", type: "uint256" },
      { name: "requiredAmount", internalType: "uint256", type: "uint256" },
    ],
    name: "InsufficientCollateral",
  },
  { type: "error", inputs: [], name: "MISMATCH" },
  { type: "error", inputs: [], name: "MaxPointsReached" },
  { type: "error", inputs: [], name: "NONCE_NOT_AVAILABLE" },
  { type: "error", inputs: [], name: "NOT_APPROVED_STRATEGY" },
  { type: "error", inputs: [], name: "NOT_ENOUGH_FUNDS" },
  { type: "error", inputs: [], name: "NOT_IMPLEMENTED" },
  { type: "error", inputs: [], name: "NOT_INITIALIZED" },
  { type: "error", inputs: [], name: "NOT_PENDING_OWNER" },
  {
    type: "error",
    inputs: [
      { name: "pointsSupport", internalType: "uint256", type: "uint256" },
      { name: "pointsBalance", internalType: "uint256", type: "uint256" },
    ],
    name: "NotEnoughPointsToSupport",
  },
  { type: "error", inputs: [], name: "NotImplemented" },
  { type: "error", inputs: [], name: "OnlyArbitrator" },
  { type: "error", inputs: [], name: "OnlyCommunityAllowed" },
  { type: "error", inputs: [], name: "OnlyCouncilSafe" },
  {
    type: "error",
    inputs: [
      { name: "submitter", internalType: "address", type: "address" },
      { name: "sender", internalType: "address", type: "address" },
    ],
    name: "OnlySubmitter",
  },
  { type: "error", inputs: [], name: "POOL_ACTIVE" },
  { type: "error", inputs: [], name: "POOL_INACTIVE" },
  {
    type: "error",
    inputs: [
      { name: "_proposalId", internalType: "uint256", type: "uint256" },
      { name: "_requestedAmount", internalType: "uint256", type: "uint256" },
      { name: "_poolAmount", internalType: "uint256", type: "uint256" },
    ],
    name: "PoolAmountNotEnough",
  },
  { type: "error", inputs: [], name: "PoolIdCannotBeZero" },
  { type: "error", inputs: [], name: "PoolIsEmpty" },
  { type: "error", inputs: [], name: "ProposalDataIsEmpty" },
  { type: "error", inputs: [], name: "ProposalIdCannotBeZero" },
  {
    type: "error",
    inputs: [{ name: "_proposalId", internalType: "uint256", type: "uint256" }],
    name: "ProposalNotActive",
  },
  {
    type: "error",
    inputs: [{ name: "_proposalId", internalType: "uint256", type: "uint256" }],
    name: "ProposalNotDisputed",
  },
  {
    type: "error",
    inputs: [{ name: "_proposalId", internalType: "uint256", type: "uint256" }],
    name: "ProposalNotInList",
  },
  {
    type: "error",
    inputs: [
      { name: "_proposalId", internalType: "uint256", type: "uint256" },
      { name: "index", internalType: "uint256", type: "uint256" },
    ],
    name: "ProposalSupportDuplicated",
  },
  { type: "error", inputs: [], name: "RECIPIENT_ALREADY_ACCEPTED" },
  {
    type: "error",
    inputs: [{ name: "recipientId", internalType: "address", type: "address" }],
    name: "RECIPIENT_ERROR",
  },
  { type: "error", inputs: [], name: "RECIPIENT_NOT_ACCEPTED" },
  { type: "error", inputs: [], name: "REGISTRATION_NOT_ACTIVE" },
  { type: "error", inputs: [], name: "RegistryCannotBeZero" },
  {
    type: "error",
    inputs: [
      { name: "_support", internalType: "uint256", type: "uint256" },
      { name: "_delta", internalType: "int256", type: "int256" },
      { name: "_result", internalType: "int256", type: "int256" },
    ],
    name: "SupportUnderflow",
  },
  { type: "error", inputs: [], name: "TokenCannotBeZero" },
  { type: "error", inputs: [], name: "TokenNotAllowed" },
  { type: "error", inputs: [], name: "UNAUTHORIZED" },
  { type: "error", inputs: [], name: "UserCannotBeZero" },
  { type: "error", inputs: [], name: "UserCannotExecuteAction" },
  { type: "error", inputs: [], name: "UserIsInactive" },
  { type: "error", inputs: [], name: "UserNotInRegistry" },
  { type: "error", inputs: [], name: "ZERO_ADDRESS" },
] as const;

//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// ERC20
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

export const erc20ABI = [
  {
    stateMutability: "view",
    type: "function",
    inputs: [],
    name: "DOMAIN_SEPARATOR",
    outputs: [{ name: "", internalType: "bytes32", type: "bytes32" }],
  },
  {
    stateMutability: "view",
    type: "function",
    inputs: [
      { name: "owner", internalType: "address", type: "address" },
      { name: "spender", internalType: "address", type: "address" },
    ],
    name: "allowance",
    outputs: [{ name: "", internalType: "uint256", type: "uint256" }],
  },
  {
    stateMutability: "nonpayable",
    type: "function",
    inputs: [
      { name: "spender", internalType: "address", type: "address" },
      { name: "amount", internalType: "uint256", type: "uint256" },
    ],
    name: "approve",
    outputs: [{ name: "", internalType: "bool", type: "bool" }],
  },
  {
    stateMutability: "view",
    type: "function",
    inputs: [{ name: "owner", internalType: "address", type: "address" }],
    name: "balanceOf",
    outputs: [{ name: "", internalType: "uint256", type: "uint256" }],
  },
  {
    stateMutability: "view",
    type: "function",
    inputs: [],
    name: "decimals",
    outputs: [{ name: "", internalType: "uint8", type: "uint8" }],
  },
  {
    stateMutability: "nonpayable",
    type: "function",
    inputs: [
      { name: "name_", internalType: "string", type: "string" },
      { name: "symbol_", internalType: "string", type: "string" },
      { name: "decimals_", internalType: "uint8", type: "uint8" },
    ],
    name: "initialize",
    outputs: [],
  },
  {
    stateMutability: "view",
    type: "function",
    inputs: [],
    name: "name",
    outputs: [{ name: "", internalType: "string", type: "string" }],
  },
  {
    stateMutability: "view",
    type: "function",
    inputs: [{ name: "", internalType: "address", type: "address" }],
    name: "nonces",
    outputs: [{ name: "", internalType: "uint256", type: "uint256" }],
  },
  {
    stateMutability: "nonpayable",
    type: "function",
    inputs: [
      { name: "owner", internalType: "address", type: "address" },
      { name: "spender", internalType: "address", type: "address" },
      { name: "value", internalType: "uint256", type: "uint256" },
      { name: "deadline", internalType: "uint256", type: "uint256" },
      { name: "v", internalType: "uint8", type: "uint8" },
      { name: "r", internalType: "bytes32", type: "bytes32" },
      { name: "s", internalType: "bytes32", type: "bytes32" },
    ],
    name: "permit",
    outputs: [],
  },
  {
    stateMutability: "view",
    type: "function",
    inputs: [],
    name: "symbol",
    outputs: [{ name: "", internalType: "string", type: "string" }],
  },
  {
    stateMutability: "view",
    type: "function",
    inputs: [],
    name: "totalSupply",
    outputs: [{ name: "", internalType: "uint256", type: "uint256" }],
  },
  {
    stateMutability: "nonpayable",
    type: "function",
    inputs: [
      { name: "to", internalType: "address", type: "address" },
      { name: "amount", internalType: "uint256", type: "uint256" },
    ],
    name: "transfer",
    outputs: [{ name: "", internalType: "bool", type: "bool" }],
  },
  {
    stateMutability: "nonpayable",
    type: "function",
    inputs: [
      { name: "from", internalType: "address", type: "address" },
      { name: "to", internalType: "address", type: "address" },
      { name: "amount", internalType: "uint256", type: "uint256" },
    ],
    name: "transferFrom",
    outputs: [{ name: "", internalType: "bool", type: "bool" }],
  },
  {
    type: "event",
    anonymous: false,
    inputs: [
      {
        name: "owner",
        internalType: "address",
        type: "address",
        indexed: true,
      },
      {
        name: "spender",
        internalType: "address",
        type: "address",
        indexed: true,
      },
      {
        name: "value",
        internalType: "uint256",
        type: "uint256",
        indexed: false,
      },
    ],
    name: "Approval",
  },
  {
    type: "event",
    anonymous: false,
    inputs: [
      { name: "from", internalType: "address", type: "address", indexed: true },
      { name: "to", internalType: "address", type: "address", indexed: true },
      {
        name: "value",
        internalType: "uint256",
        type: "uint256",
        indexed: false,
      },
    ],
    name: "Transfer",
  },
] as const;

//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// IArbitrator
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

export const iArbitratorABI = [
  {
    stateMutability: "view",
    type: "function",
    inputs: [
      { name: "_extraData", internalType: "bytes", type: "bytes" },
      { name: "_feeToken", internalType: "contract IERC20", type: "address" },
    ],
    name: "arbitrationCost",
    outputs: [{ name: "cost", internalType: "uint256", type: "uint256" }],
  },
  {
    stateMutability: "view",
    type: "function",
    inputs: [{ name: "_extraData", internalType: "bytes", type: "bytes" }],
    name: "arbitrationCost",
    outputs: [{ name: "cost", internalType: "uint256", type: "uint256" }],
  },
  {
    stateMutability: "payable",
    type: "function",
    inputs: [
      { name: "_numberOfChoices", internalType: "uint256", type: "uint256" },
      { name: "_extraData", internalType: "bytes", type: "bytes" },
    ],
    name: "createDispute",
    outputs: [{ name: "disputeID", internalType: "uint256", type: "uint256" }],
  },
  {
    stateMutability: "nonpayable",
    type: "function",
    inputs: [
      { name: "_numberOfChoices", internalType: "uint256", type: "uint256" },
      { name: "_extraData", internalType: "bytes", type: "bytes" },
      { name: "_feeToken", internalType: "contract IERC20", type: "address" },
      { name: "_feeAmount", internalType: "uint256", type: "uint256" },
    ],
    name: "createDispute",
    outputs: [{ name: "disputeID", internalType: "uint256", type: "uint256" }],
  },
  {
    stateMutability: "view",
    type: "function",
    inputs: [{ name: "_disputeID", internalType: "uint256", type: "uint256" }],
    name: "currentRuling",
    outputs: [
      { name: "ruling", internalType: "uint256", type: "uint256" },
      { name: "tied", internalType: "bool", type: "bool" },
      { name: "overridden", internalType: "bool", type: "bool" },
    ],
  },
  {
    stateMutability: "nonpayable",
    type: "function",
    inputs: [{ name: "_safe", internalType: "address", type: "address" }],
    name: "registerSafe",
    outputs: [],
  },
  {
    type: "event",
    anonymous: false,
    inputs: [
      {
        name: "_token",
        internalType: "contract IERC20",
        type: "address",
        indexed: true,
      },
      { name: "_accepted", internalType: "bool", type: "bool", indexed: true },
    ],
    name: "AcceptedFeeToken",
  },
  {
    type: "event",
    anonymous: false,
    inputs: [
      {
        name: "_disputeID",
        internalType: "uint256",
        type: "uint256",
        indexed: true,
      },
      {
        name: "_arbitrable",
        internalType: "contract IArbitrable",
        type: "address",
        indexed: true,
      },
    ],
    name: "DisputeCreation",
  },
  {
    type: "event",
    anonymous: false,
    inputs: [
      {
        name: "_feeToken",
        internalType: "contract IERC20",
        type: "address",
        indexed: true,
      },
      {
        name: "_rateInEth",
        internalType: "uint64",
        type: "uint64",
        indexed: false,
      },
      {
        name: "_rateDecimals",
        internalType: "uint8",
        type: "uint8",
        indexed: false,
      },
    ],
    name: "NewCurrencyRate",
  },
  {
    type: "event",
    anonymous: false,
    inputs: [
      {
        name: "_arbitrable",
        internalType: "contract IArbitrable",
        type: "address",
        indexed: true,
      },
      {
        name: "_disputeID",
        internalType: "uint256",
        type: "uint256",
        indexed: true,
      },
      {
        name: "_ruling",
        internalType: "uint256",
        type: "uint256",
        indexed: false,
      },
    ],
    name: "Ruling",
  },
] as const;

//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// PassportScorer
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

export const passportScorerABI = [
  {
    stateMutability: "nonpayable",
    type: "function",
    inputs: [{ name: "_strategy", internalType: "address", type: "address" }],
    name: "activateStrategy",
    outputs: [],
  },
  {
    stateMutability: "nonpayable",
    type: "function",
    inputs: [
      { name: "_strategy", internalType: "address", type: "address" },
      { name: "_threshold", internalType: "uint256", type: "uint256" },
      { name: "_councilSafe", internalType: "address", type: "address" },
    ],
    name: "addStrategy",
    outputs: [],
  },
  {
    stateMutability: "nonpayable",
    type: "function",
    inputs: [
      { name: "_user", internalType: "address", type: "address" },
      {
        name: "_passportData",
        internalType: "struct PassportData",
        type: "tuple",
        components: [
          { name: "score", internalType: "uint256", type: "uint256" },
          { name: "lastUpdated", internalType: "uint256", type: "uint256" },
        ],
      },
    ],
    name: "addUserScore",
    outputs: [],
  },
  {
    stateMutability: "view",
    type: "function",
    inputs: [
      { name: "_user", internalType: "address", type: "address" },
      { name: "_strategy", internalType: "address", type: "address" },
    ],
    name: "canExecuteAction",
    outputs: [{ name: "", internalType: "bool", type: "bool" }],
  },
  {
    stateMutability: "nonpayable",
    type: "function",
    inputs: [{ name: "_newManager", internalType: "address", type: "address" }],
    name: "changeListManager",
    outputs: [],
  },
  {
    stateMutability: "view",
    type: "function",
    inputs: [{ name: "_strategy", internalType: "address", type: "address" }],
    name: "getStrategy",
    outputs: [
      {
        name: "",
        internalType: "struct Strategy",
        type: "tuple",
        components: [
          { name: "threshold", internalType: "uint256", type: "uint256" },
          { name: "active", internalType: "bool", type: "bool" },
          { name: "councilSafe", internalType: "address", type: "address" },
        ],
      },
    ],
  },
  {
    stateMutability: "view",
    type: "function",
    inputs: [{ name: "_user", internalType: "address", type: "address" }],
    name: "getUserScore",
    outputs: [
      {
        name: "",
        internalType: "struct PassportData",
        type: "tuple",
        components: [
          { name: "score", internalType: "uint256", type: "uint256" },
          { name: "lastUpdated", internalType: "uint256", type: "uint256" },
        ],
      },
    ],
  },
  {
    stateMutability: "nonpayable",
    type: "function",
    inputs: [
      { name: "_listManager", internalType: "address", type: "address" },
    ],
    name: "initialize",
    outputs: [],
  },
  {
    stateMutability: "view",
    type: "function",
    inputs: [],
    name: "listManager",
    outputs: [{ name: "", internalType: "address", type: "address" }],
  },
  {
    stateMutability: "nonpayable",
    type: "function",
    inputs: [
      { name: "_strategy", internalType: "address", type: "address" },
      { name: "_newThreshold", internalType: "uint256", type: "uint256" },
    ],
    name: "modifyThreshold",
    outputs: [],
  },
  {
    stateMutability: "view",
    type: "function",
    inputs: [],
    name: "owner",
    outputs: [{ name: "", internalType: "address", type: "address" }],
  },
  {
    stateMutability: "view",
    type: "function",
    inputs: [],
    name: "proxiableUUID",
    outputs: [{ name: "", internalType: "bytes32", type: "bytes32" }],
  },
  {
    stateMutability: "nonpayable",
    type: "function",
    inputs: [{ name: "_strategy", internalType: "address", type: "address" }],
    name: "removeStrategy",
    outputs: [],
  },
  {
    stateMutability: "nonpayable",
    type: "function",
    inputs: [{ name: "_user", internalType: "address", type: "address" }],
    name: "removeUser",
    outputs: [],
  },
  {
    stateMutability: "nonpayable",
    type: "function",
    inputs: [],
    name: "renounceOwnership",
    outputs: [],
  },
  {
    stateMutability: "view",
    type: "function",
    inputs: [{ name: "", internalType: "address", type: "address" }],
    name: "strategies",
    outputs: [
      { name: "threshold", internalType: "uint256", type: "uint256" },
      { name: "active", internalType: "bool", type: "bool" },
      { name: "councilSafe", internalType: "address", type: "address" },
    ],
  },
  {
    stateMutability: "nonpayable",
    type: "function",
    inputs: [{ name: "newOwner", internalType: "address", type: "address" }],
    name: "transferOwnership",
    outputs: [],
  },
  {
    stateMutability: "nonpayable",
    type: "function",
    inputs: [
      { name: "newImplementation", internalType: "address", type: "address" },
    ],
    name: "upgradeTo",
    outputs: [],
  },
  {
    stateMutability: "payable",
    type: "function",
    inputs: [
      { name: "newImplementation", internalType: "address", type: "address" },
      { name: "data", internalType: "bytes", type: "bytes" },
    ],
    name: "upgradeToAndCall",
    outputs: [],
  },
  {
    stateMutability: "view",
    type: "function",
    inputs: [{ name: "", internalType: "address", type: "address" }],
    name: "userScores",
    outputs: [
      { name: "score", internalType: "uint256", type: "uint256" },
      { name: "lastUpdated", internalType: "uint256", type: "uint256" },
    ],
  },
  {
    type: "event",
    anonymous: false,
    inputs: [
      {
        name: "previousAdmin",
        internalType: "address",
        type: "address",
        indexed: false,
      },
      {
        name: "newAdmin",
        internalType: "address",
        type: "address",
        indexed: false,
      },
    ],
    name: "AdminChanged",
  },
  {
    type: "event",
    anonymous: false,
    inputs: [
      {
        name: "beacon",
        internalType: "address",
        type: "address",
        indexed: true,
      },
    ],
    name: "BeaconUpgraded",
  },
  {
    type: "event",
    anonymous: false,
    inputs: [
      { name: "version", internalType: "uint8", type: "uint8", indexed: false },
    ],
    name: "Initialized",
  },
  {
    type: "event",
    anonymous: false,
    inputs: [
      {
        name: "oldManager",
        internalType: "address",
        type: "address",
        indexed: true,
      },
      {
        name: "newManager",
        internalType: "address",
        type: "address",
        indexed: true,
      },
    ],
    name: "ListManagerChanged",
  },
  {
    type: "event",
    anonymous: false,
    inputs: [
      {
        name: "previousOwner",
        internalType: "address",
        type: "address",
        indexed: true,
      },
      {
        name: "newOwner",
        internalType: "address",
        type: "address",
        indexed: true,
      },
    ],
    name: "OwnershipTransferred",
  },
  {
    type: "event",
    anonymous: false,
    inputs: [
      {
        name: "strategy",
        internalType: "address",
        type: "address",
        indexed: true,
      },
    ],
    name: "StrategyActivated",
  },
  {
    type: "event",
    anonymous: false,
    inputs: [
      {
        name: "strategy",
        internalType: "address",
        type: "address",
        indexed: true,
      },
      {
        name: "threshold",
        internalType: "uint256",
        type: "uint256",
        indexed: false,
      },
      { name: "active", internalType: "bool", type: "bool", indexed: false },
      {
        name: "councilSafe",
        internalType: "address",
        type: "address",
        indexed: false,
      },
    ],
    name: "StrategyAdded",
  },
  {
    type: "event",
    anonymous: false,
    inputs: [
      {
        name: "strategy",
        internalType: "address",
        type: "address",
        indexed: true,
      },
    ],
    name: "StrategyRemoved",
  },
  {
    type: "event",
    anonymous: false,
    inputs: [
      {
        name: "strategy",
        internalType: "address",
        type: "address",
        indexed: true,
      },
      {
        name: "newThreshold",
        internalType: "uint256",
        type: "uint256",
        indexed: false,
      },
    ],
    name: "ThresholdModified",
  },
  {
    type: "event",
    anonymous: false,
    inputs: [
      {
        name: "implementation",
        internalType: "address",
        type: "address",
        indexed: true,
      },
    ],
    name: "Upgraded",
  },
  {
    type: "event",
    anonymous: false,
    inputs: [
      { name: "user", internalType: "address", type: "address", indexed: true },
    ],
    name: "UserRemoved",
  },
  {
    type: "event",
    anonymous: false,
    inputs: [
      { name: "user", internalType: "address", type: "address", indexed: true },
      {
        name: "score",
        internalType: "uint256",
        type: "uint256",
        indexed: false,
      },
      {
        name: "lastUpdated",
        internalType: "uint256",
        type: "uint256",
        indexed: false,
      },
    ],
    name: "UserScoreAdded",
  },
  { type: "error", inputs: [], name: "OnlyAuthorized" },
  { type: "error", inputs: [], name: "OnlyCouncil" },
  { type: "error", inputs: [], name: "OnlyCouncilOrAuthorized" },
  { type: "error", inputs: [], name: "StrategyAlreadyExists" },
  { type: "error", inputs: [], name: "ZeroAddress" },
] as const;

//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// RegistryCommunity
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

export const registryCommunityABI = [
  {
    stateMutability: "view",
    type: "function",
    inputs: [],
    name: "COUNCIL_MEMBER",
    outputs: [{ name: "", internalType: "bytes32", type: "bytes32" }],
  },
  {
    stateMutability: "view",
    type: "function",
    inputs: [],
    name: "DEFAULT_ADMIN_ROLE",
    outputs: [{ name: "", internalType: "bytes32", type: "bytes32" }],
  },
  {
    stateMutability: "view",
    type: "function",
    inputs: [],
    name: "MAX_FEE",
    outputs: [{ name: "", internalType: "uint256", type: "uint256" }],
  },
  {
    stateMutability: "view",
    type: "function",
    inputs: [],
    name: "NATIVE",
    outputs: [{ name: "", internalType: "address", type: "address" }],
  },
  {
    stateMutability: "view",
    type: "function",
    inputs: [],
    name: "PRECISION_SCALE",
    outputs: [{ name: "", internalType: "uint256", type: "uint256" }],
  },
  {
    stateMutability: "view",
    type: "function",
    inputs: [],
    name: "VERSION",
    outputs: [{ name: "", internalType: "string", type: "string" }],
  },
  {
    stateMutability: "nonpayable",
    type: "function",
    inputs: [],
    name: "acceptCouncilSafe",
    outputs: [],
  },
  {
    stateMutability: "nonpayable",
    type: "function",
    inputs: [
      { name: "_member", internalType: "address", type: "address" },
      { name: "_strategy", internalType: "address", type: "address" },
    ],
    name: "activateMemberInStrategy",
    outputs: [],
  },
  {
    stateMutability: "nonpayable",
    type: "function",
    inputs: [
      { name: "_newStrategy", internalType: "address", type: "address" },
    ],
    name: "addStrategy",
    outputs: [],
  },
  {
    stateMutability: "nonpayable",
    type: "function",
    inputs: [{ name: "poolId", internalType: "uint256", type: "uint256" }],
    name: "addStrategyByPoolId",
    outputs: [],
  },
  {
    stateMutability: "view",
    type: "function",
    inputs: [{ name: "member", internalType: "address", type: "address" }],
    name: "addressToMemberInfo",
    outputs: [
      { name: "member", internalType: "address", type: "address" },
      { name: "stakedAmount", internalType: "uint256", type: "uint256" },
      { name: "isRegistered", internalType: "bool", type: "bool" },
    ],
  },
  {
    stateMutability: "view",
    type: "function",
    inputs: [],
    name: "allo",
    outputs: [{ name: "", internalType: "contract FAllo", type: "address" }],
  },
  {
    stateMutability: "view",
    type: "function",
    inputs: [],
    name: "cloneNonce",
    outputs: [{ name: "", internalType: "uint256", type: "uint256" }],
  },
  {
    stateMutability: "view",
    type: "function",
    inputs: [],
    name: "collateralVaultTemplate",
    outputs: [{ name: "", internalType: "address", type: "address" }],
  },
  {
    stateMutability: "view",
    type: "function",
    inputs: [],
    name: "communityFee",
    outputs: [{ name: "", internalType: "uint256", type: "uint256" }],
  },
  {
    stateMutability: "view",
    type: "function",
    inputs: [],
    name: "communityName",
    outputs: [{ name: "", internalType: "string", type: "string" }],
  },
  {
    stateMutability: "view",
    type: "function",
    inputs: [],
    name: "councilSafe",
    outputs: [{ name: "", internalType: "contract ISafe", type: "address" }],
  },
  {
    stateMutability: "view",
    type: "function",
    inputs: [],
    name: "covenantIpfsHash",
    outputs: [{ name: "", internalType: "string", type: "string" }],
  },
  {
    stateMutability: "nonpayable",
    type: "function",
    inputs: [
      { name: "_token", internalType: "address", type: "address" },
      {
        name: "_params",
        internalType: "struct StrategyStruct.InitializeParams",
        type: "tuple",
        components: [
          {
            name: "cvParams",
            internalType: "struct StrategyStruct.CVParams",
            type: "tuple",
            components: [
              { name: "maxRatio", internalType: "uint256", type: "uint256" },
              { name: "weight", internalType: "uint256", type: "uint256" },
              { name: "decay", internalType: "uint256", type: "uint256" },
              {
                name: "minThresholdPoints",
                internalType: "uint256",
                type: "uint256",
              },
            ],
          },
          {
            name: "proposalType",
            internalType: "enum StrategyStruct.ProposalType",
            type: "uint8",
          },
          {
            name: "pointSystem",
            internalType: "enum StrategyStruct.PointSystem",
            type: "uint8",
          },
          {
            name: "pointConfig",
            internalType: "struct StrategyStruct.PointSystemConfig",
            type: "tuple",
            components: [
              { name: "maxAmount", internalType: "uint256", type: "uint256" },
            ],
          },
          {
            name: "arbitrableConfig",
            internalType: "struct StrategyStruct.ArbitrableConfig",
            type: "tuple",
            components: [
              {
                name: "arbitrator",
                internalType: "contract IArbitrator",
                type: "address",
              },
              {
                name: "tribunalSafe",
                internalType: "address",
                type: "address",
              },
              {
                name: "submitterCollateralAmount",
                internalType: "uint256",
                type: "uint256",
              },
              {
                name: "challengerCollateralAmount",
                internalType: "uint256",
                type: "uint256",
              },
              {
                name: "defaultRuling",
                internalType: "uint256",
                type: "uint256",
              },
              {
                name: "defaultRulingTimeout",
                internalType: "uint256",
                type: "uint256",
              },
            ],
          },
          {
            name: "registryCommunity",
            internalType: "address",
            type: "address",
          },
          { name: "sybilScorer", internalType: "address", type: "address" },
        ],
      },
      {
        name: "_metadata",
        internalType: "struct Metadata",
        type: "tuple",
        components: [
          { name: "protocol", internalType: "uint256", type: "uint256" },
          { name: "pointer", internalType: "string", type: "string" },
        ],
      },
    ],
    name: "createPool",
    outputs: [
      { name: "poolId", internalType: "uint256", type: "uint256" },
      { name: "strategy", internalType: "address", type: "address" },
    ],
  },
  {
    stateMutability: "nonpayable",
    type: "function",
    inputs: [
      { name: "_strategy", internalType: "address", type: "address" },
      { name: "_token", internalType: "address", type: "address" },
      {
        name: "_params",
        internalType: "struct StrategyStruct.InitializeParams",
        type: "tuple",
        components: [
          {
            name: "cvParams",
            internalType: "struct StrategyStruct.CVParams",
            type: "tuple",
            components: [
              { name: "maxRatio", internalType: "uint256", type: "uint256" },
              { name: "weight", internalType: "uint256", type: "uint256" },
              { name: "decay", internalType: "uint256", type: "uint256" },
              {
                name: "minThresholdPoints",
                internalType: "uint256",
                type: "uint256",
              },
            ],
          },
          {
            name: "proposalType",
            internalType: "enum StrategyStruct.ProposalType",
            type: "uint8",
          },
          {
            name: "pointSystem",
            internalType: "enum StrategyStruct.PointSystem",
            type: "uint8",
          },
          {
            name: "pointConfig",
            internalType: "struct StrategyStruct.PointSystemConfig",
            type: "tuple",
            components: [
              { name: "maxAmount", internalType: "uint256", type: "uint256" },
            ],
          },
          {
            name: "arbitrableConfig",
            internalType: "struct StrategyStruct.ArbitrableConfig",
            type: "tuple",
            components: [
              {
                name: "arbitrator",
                internalType: "contract IArbitrator",
                type: "address",
              },
              {
                name: "tribunalSafe",
                internalType: "address",
                type: "address",
              },
              {
                name: "submitterCollateralAmount",
                internalType: "uint256",
                type: "uint256",
              },
              {
                name: "challengerCollateralAmount",
                internalType: "uint256",
                type: "uint256",
              },
              {
                name: "defaultRuling",
                internalType: "uint256",
                type: "uint256",
              },
              {
                name: "defaultRulingTimeout",
                internalType: "uint256",
                type: "uint256",
              },
            ],
          },
          {
            name: "registryCommunity",
            internalType: "address",
            type: "address",
          },
          { name: "sybilScorer", internalType: "address", type: "address" },
        ],
      },
      {
        name: "_metadata",
        internalType: "struct Metadata",
        type: "tuple",
        components: [
          { name: "protocol", internalType: "uint256", type: "uint256" },
          { name: "pointer", internalType: "string", type: "string" },
        ],
      },
    ],
    name: "createPool",
    outputs: [
      { name: "poolId", internalType: "uint256", type: "uint256" },
      { name: "strategy", internalType: "address", type: "address" },
    ],
  },
  {
    stateMutability: "nonpayable",
    type: "function",
    inputs: [
      { name: "_member", internalType: "address", type: "address" },
      { name: "_strategy", internalType: "address", type: "address" },
    ],
    name: "deactivateMemberInStrategy",
    outputs: [],
  },
  {
    stateMutability: "nonpayable",
    type: "function",
    inputs: [
      { name: "_amountUnstaked", internalType: "uint256", type: "uint256" },
    ],
    name: "decreasePower",
    outputs: [],
  },
  {
    stateMutability: "view",
    type: "function",
    inputs: [{ name: "strategy", internalType: "address", type: "address" }],
    name: "enabledStrategies",
    outputs: [{ name: "isEnabled", internalType: "bool", type: "bool" }],
  },
  {
    stateMutability: "view",
    type: "function",
    inputs: [],
    name: "feeReceiver",
    outputs: [{ name: "", internalType: "address", type: "address" }],
  },
  {
    stateMutability: "view",
    type: "function",
    inputs: [],
    name: "gardenToken",
    outputs: [{ name: "", internalType: "contract IERC20", type: "address" }],
  },
  {
    stateMutability: "view",
    type: "function",
    inputs: [],
    name: "getBasisStakedAmount",
    outputs: [{ name: "", internalType: "uint256", type: "uint256" }],
  },
  {
    stateMutability: "view",
    type: "function",
    inputs: [
      { name: "_member", internalType: "address", type: "address" },
      { name: "_strategy", internalType: "address", type: "address" },
    ],
    name: "getMemberPowerInStrategy",
    outputs: [{ name: "", internalType: "uint256", type: "uint256" }],
  },
  {
    stateMutability: "view",
    type: "function",
    inputs: [{ name: "_member", internalType: "address", type: "address" }],
    name: "getMemberStakedAmount",
    outputs: [{ name: "", internalType: "uint256", type: "uint256" }],
  },
  {
    stateMutability: "view",
    type: "function",
    inputs: [{ name: "role", internalType: "bytes32", type: "bytes32" }],
    name: "getRoleAdmin",
    outputs: [{ name: "", internalType: "bytes32", type: "bytes32" }],
  },
  {
    stateMutability: "view",
    type: "function",
    inputs: [],
    name: "getStakeAmountWithFees",
    outputs: [{ name: "", internalType: "uint256", type: "uint256" }],
  },
  {
    stateMutability: "nonpayable",
    type: "function",
    inputs: [
      { name: "role", internalType: "bytes32", type: "bytes32" },
      { name: "account", internalType: "address", type: "address" },
    ],
    name: "grantRole",
    outputs: [],
  },
  {
    stateMutability: "view",
    type: "function",
    inputs: [
      { name: "role", internalType: "bytes32", type: "bytes32" },
      { name: "account", internalType: "address", type: "address" },
    ],
    name: "hasRole",
    outputs: [{ name: "", internalType: "bool", type: "bool" }],
  },
  {
    stateMutability: "nonpayable",
    type: "function",
    inputs: [
      { name: "_amountStaked", internalType: "uint256", type: "uint256" },
    ],
    name: "increasePower",
    outputs: [],
  },
  {
    stateMutability: "nonpayable",
    type: "function",
    inputs: [
      {
        name: "params",
        internalType: "struct RegistryCommunityV0_0.InitializeParams",
        type: "tuple",
        components: [
          { name: "_allo", internalType: "address", type: "address" },
          {
            name: "_gardenToken",
            internalType: "contract IERC20",
            type: "address",
          },
          {
            name: "_registerStakeAmount",
            internalType: "uint256",
            type: "uint256",
          },
          { name: "_communityFee", internalType: "uint256", type: "uint256" },
          { name: "_nonce", internalType: "uint256", type: "uint256" },
          {
            name: "_registryFactory",
            internalType: "address",
            type: "address",
          },
          { name: "_feeReceiver", internalType: "address", type: "address" },
          {
            name: "_metadata",
            internalType: "struct Metadata",
            type: "tuple",
            components: [
              { name: "protocol", internalType: "uint256", type: "uint256" },
              { name: "pointer", internalType: "string", type: "string" },
            ],
          },
          {
            name: "_councilSafe",
            internalType: "address payable",
            type: "address",
          },
          { name: "_communityName", internalType: "string", type: "string" },
          { name: "_isKickEnabled", internalType: "bool", type: "bool" },
          { name: "covenantIpfsHash", internalType: "string", type: "string" },
        ],
      },
      { name: "_strategyTemplate", internalType: "address", type: "address" },
      {
        name: "_collateralVaultTemplate",
        internalType: "address",
        type: "address",
      },
      { name: "owner", internalType: "address", type: "address" },
    ],
    name: "initialize",
    outputs: [],
  },
  {
    stateMutability: "nonpayable",
    type: "function",
    inputs: [
      { name: "initialOwner", internalType: "address", type: "address" },
    ],
    name: "initialize",
    outputs: [],
  },
  {
    stateMutability: "view",
    type: "function",
    inputs: [{ name: "_member", internalType: "address", type: "address" }],
    name: "isCouncilMember",
    outputs: [{ name: "", internalType: "bool", type: "bool" }],
  },
  {
    stateMutability: "view",
    type: "function",
    inputs: [],
    name: "isKickEnabled",
    outputs: [{ name: "", internalType: "bool", type: "bool" }],
  },
  {
    stateMutability: "view",
    type: "function",
    inputs: [{ name: "_member", internalType: "address", type: "address" }],
    name: "isMember",
    outputs: [{ name: "_isMember", internalType: "bool", type: "bool" }],
  },
  {
    stateMutability: "nonpayable",
    type: "function",
    inputs: [
      { name: "_member", internalType: "address", type: "address" },
      { name: "_transferAddress", internalType: "address", type: "address" },
    ],
    name: "kickMember",
    outputs: [],
  },
  {
    stateMutability: "view",
    type: "function",
    inputs: [
      { name: "member", internalType: "address", type: "address" },
      { name: "strategy", internalType: "address", type: "address" },
    ],
    name: "memberActivatedInStrategies",
    outputs: [{ name: "isActivated", internalType: "bool", type: "bool" }],
  },
  {
    stateMutability: "view",
    type: "function",
    inputs: [
      { name: "strategy", internalType: "address", type: "address" },
      { name: "member", internalType: "address", type: "address" },
    ],
    name: "memberPowerInStrategy",
    outputs: [{ name: "power", internalType: "uint256", type: "uint256" }],
  },
  {
    stateMutability: "view",
    type: "function",
    inputs: [],
    name: "owner",
    outputs: [{ name: "", internalType: "address", type: "address" }],
  },
  {
    stateMutability: "view",
    type: "function",
    inputs: [],
    name: "pendingCouncilSafe",
    outputs: [{ name: "", internalType: "address payable", type: "address" }],
  },
  {
    stateMutability: "view",
    type: "function",
    inputs: [],
    name: "profileId",
    outputs: [{ name: "", internalType: "bytes32", type: "bytes32" }],
  },
  {
    stateMutability: "view",
    type: "function",
    inputs: [],
    name: "proxiableUUID",
    outputs: [{ name: "", internalType: "bytes32", type: "bytes32" }],
  },
  {
    stateMutability: "view",
    type: "function",
    inputs: [],
    name: "registerStakeAmount",
    outputs: [{ name: "", internalType: "uint256", type: "uint256" }],
  },
  {
    stateMutability: "view",
    type: "function",
    inputs: [],
    name: "registry",
    outputs: [
      { name: "", internalType: "contract IRegistry", type: "address" },
    ],
  },
  {
    stateMutability: "view",
    type: "function",
    inputs: [],
    name: "registryFactory",
    outputs: [{ name: "", internalType: "address", type: "address" }],
  },
  {
    stateMutability: "nonpayable",
    type: "function",
    inputs: [{ name: "_strategy", internalType: "address", type: "address" }],
    name: "removeStrategy",
    outputs: [],
  },
  {
    stateMutability: "nonpayable",
    type: "function",
    inputs: [{ name: "poolId", internalType: "uint256", type: "uint256" }],
    name: "removeStrategyByPoolId",
    outputs: [],
  },
  {
    stateMutability: "nonpayable",
    type: "function",
    inputs: [],
    name: "renounceOwnership",
    outputs: [],
  },
  {
    stateMutability: "nonpayable",
    type: "function",
    inputs: [
      { name: "role", internalType: "bytes32", type: "bytes32" },
      { name: "account", internalType: "address", type: "address" },
    ],
    name: "renounceRole",
    outputs: [],
  },
  {
    stateMutability: "nonpayable",
    type: "function",
    inputs: [
      { name: "role", internalType: "bytes32", type: "bytes32" },
      { name: "account", internalType: "address", type: "address" },
    ],
    name: "revokeRole",
    outputs: [],
  },
  {
    stateMutability: "nonpayable",
    type: "function",
    inputs: [{ name: "_newAmount", internalType: "uint256", type: "uint256" }],
    name: "setBasisStakedAmount",
    outputs: [],
  },
  {
    stateMutability: "nonpayable",
    type: "function",
    inputs: [{ name: "template", internalType: "address", type: "address" }],
    name: "setCollateralVaultTemplate",
    outputs: [],
  },
  {
    stateMutability: "nonpayable",
    type: "function",
    inputs: [
      { name: "_newCommunityFee", internalType: "uint256", type: "uint256" },
    ],
    name: "setCommunityFee",
    outputs: [],
  },
  {
    stateMutability: "nonpayable",
    type: "function",
    inputs: [
      { name: "_safe", internalType: "address payable", type: "address" },
    ],
    name: "setCouncilSafe",
    outputs: [],
  },
  {
    stateMutability: "nonpayable",
    type: "function",
    inputs: [{ name: "template", internalType: "address", type: "address" }],
    name: "setStrategyTemplate",
    outputs: [],
  },
  {
    stateMutability: "nonpayable",
    type: "function",
    inputs: [],
    name: "stakeAndRegisterMember",
    outputs: [],
  },
  {
    stateMutability: "view",
    type: "function",
    inputs: [
      { name: "member", internalType: "address", type: "address" },
      { name: "", internalType: "uint256", type: "uint256" },
    ],
    name: "strategiesByMember",
    outputs: [
      { name: "strategiesAddresses", internalType: "address", type: "address" },
    ],
  },
  {
    stateMutability: "view",
    type: "function",
    inputs: [],
    name: "strategyTemplate",
    outputs: [{ name: "", internalType: "address", type: "address" }],
  },
  {
    stateMutability: "view",
    type: "function",
    inputs: [{ name: "interfaceId", internalType: "bytes4", type: "bytes4" }],
    name: "supportsInterface",
    outputs: [{ name: "", internalType: "bool", type: "bool" }],
  },
  {
    stateMutability: "nonpayable",
    type: "function",
    inputs: [{ name: "newOwner", internalType: "address", type: "address" }],
    name: "transferOwnership",
    outputs: [],
  },
  {
    stateMutability: "nonpayable",
    type: "function",
    inputs: [],
    name: "unregisterMember",
    outputs: [],
  },
  {
    stateMutability: "nonpayable",
    type: "function",
    inputs: [
      { name: "newImplementation", internalType: "address", type: "address" },
    ],
    name: "upgradeTo",
    outputs: [],
  },
  {
    stateMutability: "payable",
    type: "function",
    inputs: [
      { name: "newImplementation", internalType: "address", type: "address" },
      { name: "data", internalType: "bytes", type: "bytes" },
    ],
    name: "upgradeToAndCall",
    outputs: [],
  },
  {
    type: "event",
    anonymous: false,
    inputs: [
      {
        name: "previousAdmin",
        internalType: "address",
        type: "address",
        indexed: false,
      },
      {
        name: "newAdmin",
        internalType: "address",
        type: "address",
        indexed: false,
      },
    ],
    name: "AdminChanged",
  },
  {
    type: "event",
    anonymous: false,
    inputs: [
      {
        name: "_allo",
        internalType: "address",
        type: "address",
        indexed: false,
      },
    ],
    name: "AlloSet",
  },
  {
    type: "event",
    anonymous: false,
    inputs: [
      {
        name: "_newAmount",
        internalType: "uint256",
        type: "uint256",
        indexed: false,
      },
    ],
    name: "BasisStakedAmountSet",
  },
  {
    type: "event",
    anonymous: false,
    inputs: [
      {
        name: "beacon",
        internalType: "address",
        type: "address",
        indexed: true,
      },
    ],
    name: "BeaconUpgraded",
  },
  {
    type: "event",
    anonymous: false,
    inputs: [
      {
        name: "_newFee",
        internalType: "uint256",
        type: "uint256",
        indexed: false,
      },
    ],
    name: "CommunityFeeUpdated",
  },
  {
    type: "event",
    anonymous: false,
    inputs: [
      {
        name: "_safeOwner",
        internalType: "address",
        type: "address",
        indexed: false,
      },
      {
        name: "_newSafeOwner",
        internalType: "address",
        type: "address",
        indexed: false,
      },
    ],
    name: "CouncilSafeChangeStarted",
  },
  {
    type: "event",
    anonymous: false,
    inputs: [
      {
        name: "_safe",
        internalType: "address",
        type: "address",
        indexed: false,
      },
    ],
    name: "CouncilSafeSet",
  },
  {
    type: "event",
    anonymous: false,
    inputs: [
      { name: "version", internalType: "uint8", type: "uint8", indexed: false },
    ],
    name: "Initialized",
  },
  {
    type: "event",
    anonymous: false,
    inputs: [
      {
        name: "_member",
        internalType: "address",
        type: "address",
        indexed: false,
      },
      {
        name: "_strategy",
        internalType: "address",
        type: "address",
        indexed: false,
      },
      {
        name: "_pointsToIncrease",
        internalType: "uint256",
        type: "uint256",
        indexed: false,
      },
    ],
    name: "MemberActivatedStrategy",
  },
  {
    type: "event",
    anonymous: false,
    inputs: [
      {
        name: "_member",
        internalType: "address",
        type: "address",
        indexed: false,
      },
      {
        name: "_strategy",
        internalType: "address",
        type: "address",
        indexed: false,
      },
    ],
    name: "MemberDeactivatedStrategy",
  },
  {
    type: "event",
    anonymous: false,
    inputs: [
      {
        name: "_member",
        internalType: "address",
        type: "address",
        indexed: false,
      },
      {
        name: "_transferAddress",
        internalType: "address",
        type: "address",
        indexed: false,
      },
      {
        name: "_amountReturned",
        internalType: "uint256",
        type: "uint256",
        indexed: false,
      },
    ],
    name: "MemberKicked",
  },
  {
    type: "event",
    anonymous: false,
    inputs: [
      {
        name: "_member",
        internalType: "address",
        type: "address",
        indexed: false,
      },
      {
        name: "_unstakedAmount",
        internalType: "uint256",
        type: "uint256",
        indexed: false,
      },
    ],
    name: "MemberPowerDecreased",
  },
  {
    type: "event",
    anonymous: false,
    inputs: [
      {
        name: "_member",
        internalType: "address",
        type: "address",
        indexed: false,
      },
      {
        name: "_stakedAmount",
        internalType: "uint256",
        type: "uint256",
        indexed: false,
      },
    ],
    name: "MemberPowerIncreased",
  },
  {
    type: "event",
    anonymous: false,
    inputs: [
      {
        name: "_member",
        internalType: "address",
        type: "address",
        indexed: false,
      },
      {
        name: "_amountStaked",
        internalType: "uint256",
        type: "uint256",
        indexed: false,
      },
    ],
    name: "MemberRegistered",
  },
  {
    type: "event",
    anonymous: false,
    inputs: [
      {
        name: "_member",
        internalType: "address",
        type: "address",
        indexed: false,
      },
      {
        name: "_amountReturned",
        internalType: "uint256",
        type: "uint256",
        indexed: false,
      },
    ],
    name: "MemberUnregistered",
  },
  {
    type: "event",
    anonymous: false,
    inputs: [
      {
        name: "previousOwner",
        internalType: "address",
        type: "address",
        indexed: true,
      },
      {
        name: "newOwner",
        internalType: "address",
        type: "address",
        indexed: true,
      },
    ],
    name: "OwnershipTransferred",
  },
  {
    type: "event",
    anonymous: false,
    inputs: [
      {
        name: "_poolId",
        internalType: "uint256",
        type: "uint256",
        indexed: false,
      },
      {
        name: "_strategy",
        internalType: "address",
        type: "address",
        indexed: false,
      },
      {
        name: "_community",
        internalType: "address",
        type: "address",
        indexed: false,
      },
      {
        name: "_token",
        internalType: "address",
        type: "address",
        indexed: false,
      },
      {
        name: "_metadata",
        internalType: "struct Metadata",
        type: "tuple",
        components: [
          { name: "protocol", internalType: "uint256", type: "uint256" },
          { name: "pointer", internalType: "string", type: "string" },
        ],
        indexed: false,
      },
    ],
    name: "PoolCreated",
  },
  {
    type: "event",
    anonymous: false,
    inputs: [
      {
        name: "_profileId",
        internalType: "bytes32",
        type: "bytes32",
        indexed: false,
      },
      {
        name: "_communityName",
        internalType: "string",
        type: "string",
        indexed: false,
      },
      {
        name: "_metadata",
        internalType: "struct Metadata",
        type: "tuple",
        components: [
          { name: "protocol", internalType: "uint256", type: "uint256" },
          { name: "pointer", internalType: "string", type: "string" },
        ],
        indexed: false,
      },
    ],
    name: "RegistryInitialized",
  },
  {
    type: "event",
    anonymous: false,
    inputs: [
      { name: "role", internalType: "bytes32", type: "bytes32", indexed: true },
      {
        name: "previousAdminRole",
        internalType: "bytes32",
        type: "bytes32",
        indexed: true,
      },
      {
        name: "newAdminRole",
        internalType: "bytes32",
        type: "bytes32",
        indexed: true,
      },
    ],
    name: "RoleAdminChanged",
  },
  {
    type: "event",
    anonymous: false,
    inputs: [
      { name: "role", internalType: "bytes32", type: "bytes32", indexed: true },
      {
        name: "account",
        internalType: "address",
        type: "address",
        indexed: true,
      },
      {
        name: "sender",
        internalType: "address",
        type: "address",
        indexed: true,
      },
    ],
    name: "RoleGranted",
  },
  {
    type: "event",
    anonymous: false,
    inputs: [
      { name: "role", internalType: "bytes32", type: "bytes32", indexed: true },
      {
        name: "account",
        internalType: "address",
        type: "address",
        indexed: true,
      },
      {
        name: "sender",
        internalType: "address",
        type: "address",
        indexed: true,
      },
    ],
    name: "RoleRevoked",
  },
  {
    type: "event",
    anonymous: false,
    inputs: [
      {
        name: "_strategy",
        internalType: "address",
        type: "address",
        indexed: false,
      },
    ],
    name: "StrategyAdded",
  },
  {
    type: "event",
    anonymous: false,
    inputs: [
      {
        name: "_strategy",
        internalType: "address",
        type: "address",
        indexed: false,
      },
    ],
    name: "StrategyRemoved",
  },
  {
    type: "event",
    anonymous: false,
    inputs: [
      {
        name: "implementation",
        internalType: "address",
        type: "address",
        indexed: true,
      },
    ],
    name: "Upgraded",
  },
  { type: "error", inputs: [], name: "AddressCannotBeZero" },
  {
    type: "error",
    inputs: [
      { name: "_caller", internalType: "address", type: "address" },
      { name: "_owner", internalType: "address", type: "address" },
    ],
    name: "CallerNotOwner",
  },
  {
    type: "error",
    inputs: [
      { name: "_decreaseAmount", internalType: "uint256", type: "uint256" },
      { name: "_currentPower", internalType: "uint256", type: "uint256" },
    ],
    name: "CantDecreaseMoreThanPower",
  },
  { type: "error", inputs: [], name: "DecreaseUnderMinimum" },
  { type: "error", inputs: [], name: "KickNotEnabled" },
  { type: "error", inputs: [], name: "NewFeeGreaterThanMax" },
  { type: "error", inputs: [], name: "PointsDeactivated" },
  { type: "error", inputs: [], name: "RegistryCannotBeZero" },
  { type: "error", inputs: [], name: "SenderNotNewOwner" },
  { type: "error", inputs: [], name: "SenderNotStrategy" },
  { type: "error", inputs: [], name: "StrategyDisabled" },
  { type: "error", inputs: [], name: "StrategyExists" },
  { type: "error", inputs: [], name: "UserAlreadyActivated" },
  { type: "error", inputs: [], name: "UserAlreadyDeactivated" },
  { type: "error", inputs: [], name: "UserAlreadyRegistered" },
  { type: "error", inputs: [], name: "UserNotGardenOwner" },
  {
    type: "error",
    inputs: [{ name: "_user", internalType: "address", type: "address" }],
    name: "UserNotInCouncil",
  },
  { type: "error", inputs: [], name: "UserNotInRegistry" },
  { type: "error", inputs: [], name: "ValueCannotBeZero" },
] as const;

//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// RegistryFactory
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

export const registryFactoryABI = [
  {
    stateMutability: "view",
    type: "function",
    inputs: [],
    name: "VERSION",
    outputs: [{ name: "", internalType: "string", type: "string" }],
  },
  {
    stateMutability: "view",
    type: "function",
    inputs: [],
    name: "collateralVaultTemplate",
    outputs: [{ name: "", internalType: "address", type: "address" }],
  },
  {
    stateMutability: "nonpayable",
    type: "function",
    inputs: [
      {
        name: "params",
        internalType: "struct RegistryCommunityV0_0.InitializeParams",
        type: "tuple",
        components: [
          { name: "_allo", internalType: "address", type: "address" },
          {
            name: "_gardenToken",
            internalType: "contract IERC20",
            type: "address",
          },
          {
            name: "_registerStakeAmount",
            internalType: "uint256",
            type: "uint256",
          },
          { name: "_communityFee", internalType: "uint256", type: "uint256" },
          { name: "_nonce", internalType: "uint256", type: "uint256" },
          {
            name: "_registryFactory",
            internalType: "address",
            type: "address",
          },
          { name: "_feeReceiver", internalType: "address", type: "address" },
          {
            name: "_metadata",
            internalType: "struct Metadata",
            type: "tuple",
            components: [
              { name: "protocol", internalType: "uint256", type: "uint256" },
              { name: "pointer", internalType: "string", type: "string" },
            ],
          },
          {
            name: "_councilSafe",
            internalType: "address payable",
            type: "address",
          },
          { name: "_communityName", internalType: "string", type: "string" },
          { name: "_isKickEnabled", internalType: "bool", type: "bool" },
          { name: "covenantIpfsHash", internalType: "string", type: "string" },
        ],
      },
    ],
    name: "createRegistry",
    outputs: [
      {
        name: "_createdRegistryAddress",
        internalType: "address",
        type: "address",
      },
    ],
  },
  {
    stateMutability: "view",
    type: "function",
    inputs: [],
    name: "gardensFeeReceiver",
    outputs: [{ name: "", internalType: "address", type: "address" }],
  },
  {
    stateMutability: "view",
    type: "function",
    inputs: [{ name: "_community", internalType: "address", type: "address" }],
    name: "getCommunityValidity",
    outputs: [{ name: "", internalType: "bool", type: "bool" }],
  },
  {
    stateMutability: "view",
    type: "function",
    inputs: [],
    name: "getGardensFeeReceiver",
    outputs: [{ name: "", internalType: "address", type: "address" }],
  },
  {
    stateMutability: "view",
    type: "function",
    inputs: [{ name: "_community", internalType: "address", type: "address" }],
    name: "getProtocolFee",
    outputs: [{ name: "", internalType: "uint256", type: "uint256" }],
  },
  {
    stateMutability: "nonpayable",
    type: "function",
    inputs: [
      { name: "_owner", internalType: "address", type: "address" },
      { name: "_gardensFeeReceiver", internalType: "address", type: "address" },
      {
        name: "_registryCommunityTemplate",
        internalType: "address",
        type: "address",
      },
      { name: "_strategyTemplate", internalType: "address", type: "address" },
      {
        name: "_collateralVaultTemplate",
        internalType: "address",
        type: "address",
      },
    ],
    name: "initialize",
    outputs: [],
  },
  {
    stateMutability: "nonpayable",
    type: "function",
    inputs: [
      { name: "initialOwner", internalType: "address", type: "address" },
    ],
    name: "initialize",
    outputs: [],
  },
  {
    stateMutability: "view",
    type: "function",
    inputs: [],
    name: "nonce",
    outputs: [{ name: "", internalType: "uint256", type: "uint256" }],
  },
  {
    stateMutability: "view",
    type: "function",
    inputs: [],
    name: "owner",
    outputs: [{ name: "", internalType: "address", type: "address" }],
  },
  {
    stateMutability: "view",
    type: "function",
    inputs: [],
    name: "proxiableUUID",
    outputs: [{ name: "", internalType: "bytes32", type: "bytes32" }],
  },
  {
    stateMutability: "view",
    type: "function",
    inputs: [],
    name: "registryCommunityTemplate",
    outputs: [{ name: "", internalType: "address", type: "address" }],
  },
  {
    stateMutability: "nonpayable",
    type: "function",
    inputs: [],
    name: "renounceOwnership",
    outputs: [],
  },
  {
    stateMutability: "nonpayable",
    type: "function",
    inputs: [{ name: "template", internalType: "address", type: "address" }],
    name: "setCollateralVaultTemplate",
    outputs: [],
  },
  {
    stateMutability: "nonpayable",
    type: "function",
    inputs: [
      { name: "_community", internalType: "address", type: "address" },
      { name: "_isValid", internalType: "bool", type: "bool" },
    ],
    name: "setCommunityValidity",
    outputs: [],
  },
  {
    stateMutability: "nonpayable",
    type: "function",
    inputs: [
      { name: "_community", internalType: "address", type: "address" },
      { name: "_newProtocolFee", internalType: "uint256", type: "uint256" },
    ],
    name: "setProtocolFee",
    outputs: [],
  },
  {
    stateMutability: "nonpayable",
    type: "function",
    inputs: [
      { name: "_newFeeReceiver", internalType: "address", type: "address" },
    ],
    name: "setReceiverAddress",
    outputs: [],
  },
  {
    stateMutability: "nonpayable",
    type: "function",
    inputs: [{ name: "template", internalType: "address", type: "address" }],
    name: "setRegistryCommunityTemplate",
    outputs: [],
  },
  {
    stateMutability: "nonpayable",
    type: "function",
    inputs: [{ name: "template", internalType: "address", type: "address" }],
    name: "setStrategyTemplate",
    outputs: [],
  },
  {
    stateMutability: "view",
    type: "function",
    inputs: [],
    name: "strategyTemplate",
    outputs: [{ name: "", internalType: "address", type: "address" }],
  },
  {
    stateMutability: "nonpayable",
    type: "function",
    inputs: [{ name: "newOwner", internalType: "address", type: "address" }],
    name: "transferOwnership",
    outputs: [],
  },
  {
    stateMutability: "nonpayable",
    type: "function",
    inputs: [
      { name: "newImplementation", internalType: "address", type: "address" },
    ],
    name: "upgradeTo",
    outputs: [],
  },
  {
    stateMutability: "payable",
    type: "function",
    inputs: [
      { name: "newImplementation", internalType: "address", type: "address" },
      { name: "data", internalType: "bytes", type: "bytes" },
    ],
    name: "upgradeToAndCall",
    outputs: [],
  },
  {
    type: "event",
    anonymous: false,
    inputs: [
      {
        name: "previousAdmin",
        internalType: "address",
        type: "address",
        indexed: false,
      },
      {
        name: "newAdmin",
        internalType: "address",
        type: "address",
        indexed: false,
      },
    ],
    name: "AdminChanged",
  },
  {
    type: "event",
    anonymous: false,
    inputs: [
      {
        name: "beacon",
        internalType: "address",
        type: "address",
        indexed: true,
      },
    ],
    name: "BeaconUpgraded",
  },
  {
    type: "event",
    anonymous: false,
    inputs: [
      {
        name: "_registryCommunity",
        internalType: "address",
        type: "address",
        indexed: false,
      },
    ],
    name: "CommunityCreated",
  },
  {
    type: "event",
    anonymous: false,
    inputs: [
      {
        name: "_community",
        internalType: "address",
        type: "address",
        indexed: false,
      },
      { name: "_isValid", internalType: "bool", type: "bool", indexed: false },
    ],
    name: "CommunityValiditySet",
  },
  {
    type: "event",
    anonymous: false,
    inputs: [
      {
        name: "_newFeeReceiver",
        internalType: "address",
        type: "address",
        indexed: false,
      },
    ],
    name: "FeeReceiverSet",
  },
  {
    type: "event",
    anonymous: false,
    inputs: [
      { name: "version", internalType: "uint8", type: "uint8", indexed: false },
    ],
    name: "Initialized",
  },
  {
    type: "event",
    anonymous: false,
    inputs: [
      {
        name: "previousOwner",
        internalType: "address",
        type: "address",
        indexed: true,
      },
      {
        name: "newOwner",
        internalType: "address",
        type: "address",
        indexed: true,
      },
    ],
    name: "OwnershipTransferred",
  },
  {
    type: "event",
    anonymous: false,
    inputs: [
      {
        name: "_community",
        internalType: "address",
        type: "address",
        indexed: false,
      },
      {
        name: "_newProtocolFee",
        internalType: "uint256",
        type: "uint256",
        indexed: false,
      },
    ],
    name: "ProtocolFeeSet",
  },
  {
    type: "event",
    anonymous: false,
    inputs: [
      {
        name: "implementation",
        internalType: "address",
        type: "address",
        indexed: true,
      },
    ],
    name: "Upgraded",
  },
  { type: "error", inputs: [], name: "AddressCannotBeZero" },
  {
    type: "error",
    inputs: [
      { name: "_caller", internalType: "address", type: "address" },
      { name: "_owner", internalType: "address", type: "address" },
    ],
    name: "CallerNotOwner",
  },
  {
    type: "error",
    inputs: [{ name: "_community", internalType: "address", type: "address" }],
    name: "CommunityInvalid",
  },
] as const;

//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// Safe
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

export const safeABI = [
  {
    stateMutability: "view",
    type: "function",
    inputs: [],
    name: "getOwners",
    outputs: [{ name: "", internalType: "address[]", type: "address[]" }],
  },
  {
    stateMutability: "view",
    type: "function",
    inputs: [{ name: "owner", internalType: "address", type: "address" }],
    name: "isOwner",
    outputs: [{ name: "", internalType: "bool", type: "bool" }],
  },
] as const;

//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// SafeArbitrator
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

export const safeArbitratorABI = [
  {
    stateMutability: "view",
    type: "function",
    inputs: [{ name: "arbitrable", internalType: "address", type: "address" }],
    name: "arbitrableTribunalSafe",
    outputs: [{ name: "safe", internalType: "address", type: "address" }],
  },
  {
    stateMutability: "pure",
    type: "function",
    inputs: [
      { name: "", internalType: "bytes", type: "bytes" },
      { name: "", internalType: "contract IERC20", type: "address" },
    ],
    name: "arbitrationCost",
    outputs: [{ name: "", internalType: "uint256", type: "uint256" }],
  },
  {
    stateMutability: "view",
    type: "function",
    inputs: [{ name: "", internalType: "bytes", type: "bytes" }],
    name: "arbitrationCost",
    outputs: [{ name: "fee", internalType: "uint256", type: "uint256" }],
  },
  {
    stateMutability: "payable",
    type: "function",
    inputs: [
      { name: "_choices", internalType: "uint256", type: "uint256" },
      { name: "_extraData", internalType: "bytes", type: "bytes" },
    ],
    name: "createDispute",
    outputs: [{ name: "disputeID", internalType: "uint256", type: "uint256" }],
  },
  {
    stateMutability: "pure",
    type: "function",
    inputs: [
      { name: "", internalType: "uint256", type: "uint256" },
      { name: "", internalType: "bytes", type: "bytes" },
      { name: "", internalType: "contract IERC20", type: "address" },
      { name: "", internalType: "uint256", type: "uint256" },
    ],
    name: "createDispute",
    outputs: [{ name: "", internalType: "uint256", type: "uint256" }],
  },
  {
    stateMutability: "view",
    type: "function",
    inputs: [{ name: "_disputeID", internalType: "uint256", type: "uint256" }],
    name: "currentRuling",
    outputs: [
      { name: "ruling", internalType: "uint256", type: "uint256" },
      { name: "tied", internalType: "bool", type: "bool" },
      { name: "overridden", internalType: "bool", type: "bool" },
    ],
  },
  {
    stateMutability: "view",
    type: "function",
    inputs: [{ name: "", internalType: "uint256", type: "uint256" }],
    name: "disputes",
    outputs: [
      {
        name: "arbitrated",
        internalType: "contract IArbitrable",
        type: "address",
      },
      { name: "arbitratorExtraData", internalType: "bytes", type: "bytes" },
      { name: "choices", internalType: "uint256", type: "uint256" },
      { name: "arbitrationFee", internalType: "uint256", type: "uint256" },
      { name: "ruling", internalType: "uint256", type: "uint256" },
      {
        name: "status",
        internalType: "enum SafeArbitrator.DisputeStatus",
        type: "uint8",
      },
    ],
  },
  {
    stateMutability: "nonpayable",
    type: "function",
    inputs: [
      { name: "_disputeID", internalType: "uint256", type: "uint256" },
      { name: "_ruling", internalType: "uint256", type: "uint256" },
      { name: "_arbitrable", internalType: "address", type: "address" },
    ],
    name: "executeRuling",
    outputs: [],
  },
  {
    stateMutability: "nonpayable",
    type: "function",
    inputs: [
      { name: "_arbitrationFee", internalType: "uint256", type: "uint256" },
    ],
    name: "initialize",
    outputs: [],
  },
  {
    stateMutability: "view",
    type: "function",
    inputs: [],
    name: "owner",
    outputs: [{ name: "", internalType: "address", type: "address" }],
  },
  {
    stateMutability: "view",
    type: "function",
    inputs: [],
    name: "proxiableUUID",
    outputs: [{ name: "", internalType: "bytes32", type: "bytes32" }],
  },
  {
    stateMutability: "nonpayable",
    type: "function",
    inputs: [{ name: "_safe", internalType: "address", type: "address" }],
    name: "registerSafe",
    outputs: [],
  },
  {
    stateMutability: "nonpayable",
    type: "function",
    inputs: [],
    name: "renounceOwnership",
    outputs: [],
  },
  {
    stateMutability: "nonpayable",
    type: "function",
    inputs: [
      { name: "_arbitrationFee", internalType: "uint256", type: "uint256" },
    ],
    name: "setArbitrationFee",
    outputs: [],
  },
  {
    stateMutability: "nonpayable",
    type: "function",
    inputs: [{ name: "newOwner", internalType: "address", type: "address" }],
    name: "transferOwnership",
    outputs: [],
  },
  {
    stateMutability: "nonpayable",
    type: "function",
    inputs: [
      { name: "newImplementation", internalType: "address", type: "address" },
    ],
    name: "upgradeTo",
    outputs: [],
  },
  {
    stateMutability: "payable",
    type: "function",
    inputs: [
      { name: "newImplementation", internalType: "address", type: "address" },
      { name: "data", internalType: "bytes", type: "bytes" },
    ],
    name: "upgradeToAndCall",
    outputs: [],
  },
  {
    type: "event",
    anonymous: false,
    inputs: [
      {
        name: "_token",
        internalType: "contract IERC20",
        type: "address",
        indexed: true,
      },
      { name: "_accepted", internalType: "bool", type: "bool", indexed: true },
    ],
    name: "AcceptedFeeToken",
  },
  {
    type: "event",
    anonymous: false,
    inputs: [
      {
        name: "previousAdmin",
        internalType: "address",
        type: "address",
        indexed: false,
      },
      {
        name: "newAdmin",
        internalType: "address",
        type: "address",
        indexed: false,
      },
    ],
    name: "AdminChanged",
  },
  {
    type: "event",
    anonymous: false,
    inputs: [
      {
        name: "_newArbitrationFee",
        internalType: "uint256",
        type: "uint256",
        indexed: false,
      },
    ],
    name: "ArbitrationFeeUpdated",
  },
  {
    type: "event",
    anonymous: false,
    inputs: [
      {
        name: "beacon",
        internalType: "address",
        type: "address",
        indexed: true,
      },
    ],
    name: "BeaconUpgraded",
  },
  {
    type: "event",
    anonymous: false,
    inputs: [
      {
        name: "_disputeID",
        internalType: "uint256",
        type: "uint256",
        indexed: true,
      },
      {
        name: "_arbitrable",
        internalType: "contract IArbitrable",
        type: "address",
        indexed: true,
      },
    ],
    name: "DisputeCreation",
  },
  {
    type: "event",
    anonymous: false,
    inputs: [
      { name: "version", internalType: "uint8", type: "uint8", indexed: false },
    ],
    name: "Initialized",
  },
  {
    type: "event",
    anonymous: false,
    inputs: [
      {
        name: "_feeToken",
        internalType: "contract IERC20",
        type: "address",
        indexed: true,
      },
      {
        name: "_rateInEth",
        internalType: "uint64",
        type: "uint64",
        indexed: false,
      },
      {
        name: "_rateDecimals",
        internalType: "uint8",
        type: "uint8",
        indexed: false,
      },
    ],
    name: "NewCurrencyRate",
  },
  {
    type: "event",
    anonymous: false,
    inputs: [
      {
        name: "previousOwner",
        internalType: "address",
        type: "address",
        indexed: true,
      },
      {
        name: "newOwner",
        internalType: "address",
        type: "address",
        indexed: true,
      },
    ],
    name: "OwnershipTransferred",
  },
  {
    type: "event",
    anonymous: false,
    inputs: [
      {
        name: "_arbitrable",
        internalType: "contract IArbitrable",
        type: "address",
        indexed: true,
      },
      {
        name: "_disputeID",
        internalType: "uint256",
        type: "uint256",
        indexed: true,
      },
      {
        name: "_ruling",
        internalType: "uint256",
        type: "uint256",
        indexed: false,
      },
    ],
    name: "Ruling",
  },
  {
    type: "event",
    anonymous: false,
    inputs: [
      {
        name: "_arbitrationFee",
        internalType: "uint256",
        type: "uint256",
        indexed: false,
      },
    ],
    name: "SafeArbitratorInitialized",
  },
  {
    type: "event",
    anonymous: false,
    inputs: [
      {
        name: "_arbitrable",
        internalType: "address",
        type: "address",
        indexed: true,
      },
      {
        name: "_safe",
        internalType: "address",
        type: "address",
        indexed: false,
      },
    ],
    name: "SafeRegistered",
  },
  {
    type: "event",
    anonymous: false,
    inputs: [
      {
        name: "implementation",
        internalType: "address",
        type: "address",
        indexed: true,
      },
    ],
    name: "Upgraded",
  },
  { type: "error", inputs: [], name: "DisputeAlreadySolved" },
  { type: "error", inputs: [], name: "InvalidRuling" },
  { type: "error", inputs: [], name: "NotEnoughArbitrationFees" },
  {
    type: "error",
    inputs: [
      { name: "sender", internalType: "address", type: "address" },
      { name: "safe", internalType: "address", type: "address" },
    ],
    name: "OnlySafe",
  },
] as const;

//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// Core
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

/**
 * Wraps __{@link getContract}__ with `abi` set to __{@link alloABI}__.
 */
export function getAllo(config: Omit<GetContractArgs, "abi">) {
  return getContract({ abi: alloABI, ...config });
}

/**
 * Wraps __{@link readContract}__ with `abi` set to __{@link alloABI}__.
 */
export function readAllo<
  TAbi extends readonly unknown[] = typeof alloABI,
  TFunctionName extends string = string,
>(config: Omit<ReadContractConfig<TAbi, TFunctionName>, "abi">) {
  return readContract({
    abi: alloABI,
    ...config,
  } as unknown as ReadContractConfig<TAbi, TFunctionName>);
}

/**
 * Wraps __{@link writeContract}__ with `abi` set to __{@link alloABI}__.
 */
export function writeAllo<TFunctionName extends string>(
  config:
    | Omit<WriteContractPreparedArgs<typeof alloABI, TFunctionName>, "abi">
    | Omit<WriteContractUnpreparedArgs<typeof alloABI, TFunctionName>, "abi">,
) {
  return writeContract({
    abi: alloABI,
    ...config,
  } as unknown as WriteContractArgs<typeof alloABI, TFunctionName>);
}

/**
 * Wraps __{@link prepareWriteContract}__ with `abi` set to __{@link alloABI}__.
 */
export function prepareWriteAllo<
  TAbi extends readonly unknown[] = typeof alloABI,
  TFunctionName extends string = string,
>(config: Omit<PrepareWriteContractConfig<TAbi, TFunctionName>, "abi">) {
  return prepareWriteContract({
    abi: alloABI,
    ...config,
  } as unknown as PrepareWriteContractConfig<TAbi, TFunctionName>);
}

/**
 * Wraps __{@link getContract}__ with `abi` set to __{@link cvStrategyABI}__.
 */
export function getCvStrategy(config: Omit<GetContractArgs, "abi">) {
  return getContract({ abi: cvStrategyABI, ...config });
}

/**
 * Wraps __{@link readContract}__ with `abi` set to __{@link cvStrategyABI}__.
 */
export function readCvStrategy<
  TAbi extends readonly unknown[] = typeof cvStrategyABI,
  TFunctionName extends string = string,
>(config: Omit<ReadContractConfig<TAbi, TFunctionName>, "abi">) {
  return readContract({
    abi: cvStrategyABI,
    ...config,
  } as unknown as ReadContractConfig<TAbi, TFunctionName>);
}

/**
 * Wraps __{@link writeContract}__ with `abi` set to __{@link cvStrategyABI}__.
 */
export function writeCvStrategy<TFunctionName extends string>(
  config:
    | Omit<
        WriteContractPreparedArgs<typeof cvStrategyABI, TFunctionName>,
        "abi"
      >
    | Omit<
        WriteContractUnpreparedArgs<typeof cvStrategyABI, TFunctionName>,
        "abi"
      >,
) {
  return writeContract({
    abi: cvStrategyABI,
    ...config,
  } as unknown as WriteContractArgs<typeof cvStrategyABI, TFunctionName>);
}

/**
 * Wraps __{@link prepareWriteContract}__ with `abi` set to __{@link cvStrategyABI}__.
 */
export function prepareWriteCvStrategy<
  TAbi extends readonly unknown[] = typeof cvStrategyABI,
  TFunctionName extends string = string,
>(config: Omit<PrepareWriteContractConfig<TAbi, TFunctionName>, "abi">) {
  return prepareWriteContract({
    abi: cvStrategyABI,
    ...config,
  } as unknown as PrepareWriteContractConfig<TAbi, TFunctionName>);
}

/**
 * Wraps __{@link getContract}__ with `abi` set to __{@link erc20ABI}__.
 */
export function getErc20(config: Omit<GetContractArgs, "abi">) {
  return getContract({ abi: erc20ABI, ...config });
}

/**
 * Wraps __{@link readContract}__ with `abi` set to __{@link erc20ABI}__.
 */
export function readErc20<
  TAbi extends readonly unknown[] = typeof erc20ABI,
  TFunctionName extends string = string,
>(config: Omit<ReadContractConfig<TAbi, TFunctionName>, "abi">) {
  return readContract({
    abi: erc20ABI,
    ...config,
  } as unknown as ReadContractConfig<TAbi, TFunctionName>);
}

/**
 * Wraps __{@link writeContract}__ with `abi` set to __{@link erc20ABI}__.
 */
export function writeErc20<TFunctionName extends string>(
  config:
    | Omit<WriteContractPreparedArgs<typeof erc20ABI, TFunctionName>, "abi">
    | Omit<WriteContractUnpreparedArgs<typeof erc20ABI, TFunctionName>, "abi">,
) {
  return writeContract({
    abi: erc20ABI,
    ...config,
  } as unknown as WriteContractArgs<typeof erc20ABI, TFunctionName>);
}

/**
 * Wraps __{@link prepareWriteContract}__ with `abi` set to __{@link erc20ABI}__.
 */
export function prepareWriteErc20<
  TAbi extends readonly unknown[] = typeof erc20ABI,
  TFunctionName extends string = string,
>(config: Omit<PrepareWriteContractConfig<TAbi, TFunctionName>, "abi">) {
  return prepareWriteContract({
    abi: erc20ABI,
    ...config,
  } as unknown as PrepareWriteContractConfig<TAbi, TFunctionName>);
}

/**
 * Wraps __{@link getContract}__ with `abi` set to __{@link iArbitratorABI}__.
 */
export function getIArbitrator(config: Omit<GetContractArgs, "abi">) {
  return getContract({ abi: iArbitratorABI, ...config });
}

/**
 * Wraps __{@link readContract}__ with `abi` set to __{@link iArbitratorABI}__.
 */
export function readIArbitrator<
  TAbi extends readonly unknown[] = typeof iArbitratorABI,
  TFunctionName extends string = string,
>(config: Omit<ReadContractConfig<TAbi, TFunctionName>, "abi">) {
  return readContract({
    abi: iArbitratorABI,
    ...config,
  } as unknown as ReadContractConfig<TAbi, TFunctionName>);
}

/**
 * Wraps __{@link writeContract}__ with `abi` set to __{@link iArbitratorABI}__.
 */
export function writeIArbitrator<TFunctionName extends string>(
  config:
    | Omit<
        WriteContractPreparedArgs<typeof iArbitratorABI, TFunctionName>,
        "abi"
      >
    | Omit<
        WriteContractUnpreparedArgs<typeof iArbitratorABI, TFunctionName>,
        "abi"
      >,
) {
  return writeContract({
    abi: iArbitratorABI,
    ...config,
  } as unknown as WriteContractArgs<typeof iArbitratorABI, TFunctionName>);
}

/**
 * Wraps __{@link prepareWriteContract}__ with `abi` set to __{@link iArbitratorABI}__.
 */
export function prepareWriteIArbitrator<
  TAbi extends readonly unknown[] = typeof iArbitratorABI,
  TFunctionName extends string = string,
>(config: Omit<PrepareWriteContractConfig<TAbi, TFunctionName>, "abi">) {
  return prepareWriteContract({
    abi: iArbitratorABI,
    ...config,
  } as unknown as PrepareWriteContractConfig<TAbi, TFunctionName>);
}

/**
 * Wraps __{@link getContract}__ with `abi` set to __{@link passportScorerABI}__.
 */
export function getPassportScorer(config: Omit<GetContractArgs, "abi">) {
  return getContract({ abi: passportScorerABI, ...config });
}

/**
 * Wraps __{@link readContract}__ with `abi` set to __{@link passportScorerABI}__.
 */
export function readPassportScorer<
  TAbi extends readonly unknown[] = typeof passportScorerABI,
  TFunctionName extends string = string,
>(config: Omit<ReadContractConfig<TAbi, TFunctionName>, "abi">) {
  return readContract({
    abi: passportScorerABI,
    ...config,
  } as unknown as ReadContractConfig<TAbi, TFunctionName>);
}

/**
 * Wraps __{@link writeContract}__ with `abi` set to __{@link passportScorerABI}__.
 */
export function writePassportScorer<TFunctionName extends string>(
  config:
    | Omit<
        WriteContractPreparedArgs<typeof passportScorerABI, TFunctionName>,
        "abi"
      >
    | Omit<
        WriteContractUnpreparedArgs<typeof passportScorerABI, TFunctionName>,
        "abi"
      >,
) {
  return writeContract({
    abi: passportScorerABI,
    ...config,
  } as unknown as WriteContractArgs<typeof passportScorerABI, TFunctionName>);
}

/**
 * Wraps __{@link prepareWriteContract}__ with `abi` set to __{@link passportScorerABI}__.
 */
export function prepareWritePassportScorer<
  TAbi extends readonly unknown[] = typeof passportScorerABI,
  TFunctionName extends string = string,
>(config: Omit<PrepareWriteContractConfig<TAbi, TFunctionName>, "abi">) {
  return prepareWriteContract({
    abi: passportScorerABI,
    ...config,
  } as unknown as PrepareWriteContractConfig<TAbi, TFunctionName>);
}

/**
 * Wraps __{@link getContract}__ with `abi` set to __{@link registryCommunityABI}__.
 */
export function getRegistryCommunity(config: Omit<GetContractArgs, "abi">) {
  return getContract({ abi: registryCommunityABI, ...config });
}

/**
 * Wraps __{@link readContract}__ with `abi` set to __{@link registryCommunityABI}__.
 */
export function readRegistryCommunity<
  TAbi extends readonly unknown[] = typeof registryCommunityABI,
  TFunctionName extends string = string,
>(config: Omit<ReadContractConfig<TAbi, TFunctionName>, "abi">) {
  return readContract({
    abi: registryCommunityABI,
    ...config,
  } as unknown as ReadContractConfig<TAbi, TFunctionName>);
}

/**
 * Wraps __{@link writeContract}__ with `abi` set to __{@link registryCommunityABI}__.
 */
export function writeRegistryCommunity<TFunctionName extends string>(
  config:
    | Omit<
        WriteContractPreparedArgs<typeof registryCommunityABI, TFunctionName>,
        "abi"
      >
    | Omit<
        WriteContractUnpreparedArgs<typeof registryCommunityABI, TFunctionName>,
        "abi"
      >,
) {
  return writeContract({
    abi: registryCommunityABI,
    ...config,
  } as unknown as WriteContractArgs<
    typeof registryCommunityABI,
    TFunctionName
  >);
}

/**
 * Wraps __{@link prepareWriteContract}__ with `abi` set to __{@link registryCommunityABI}__.
 */
export function prepareWriteRegistryCommunity<
  TAbi extends readonly unknown[] = typeof registryCommunityABI,
  TFunctionName extends string = string,
>(config: Omit<PrepareWriteContractConfig<TAbi, TFunctionName>, "abi">) {
  return prepareWriteContract({
    abi: registryCommunityABI,
    ...config,
  } as unknown as PrepareWriteContractConfig<TAbi, TFunctionName>);
}

/**
 * Wraps __{@link getContract}__ with `abi` set to __{@link registryFactoryABI}__.
 */
export function getRegistryFactory(config: Omit<GetContractArgs, "abi">) {
  return getContract({ abi: registryFactoryABI, ...config });
}

/**
 * Wraps __{@link readContract}__ with `abi` set to __{@link registryFactoryABI}__.
 */
export function readRegistryFactory<
  TAbi extends readonly unknown[] = typeof registryFactoryABI,
  TFunctionName extends string = string,
>(config: Omit<ReadContractConfig<TAbi, TFunctionName>, "abi">) {
  return readContract({
    abi: registryFactoryABI,
    ...config,
  } as unknown as ReadContractConfig<TAbi, TFunctionName>);
}

/**
 * Wraps __{@link writeContract}__ with `abi` set to __{@link registryFactoryABI}__.
 */
export function writeRegistryFactory<TFunctionName extends string>(
  config:
    | Omit<
        WriteContractPreparedArgs<typeof registryFactoryABI, TFunctionName>,
        "abi"
      >
    | Omit<
        WriteContractUnpreparedArgs<typeof registryFactoryABI, TFunctionName>,
        "abi"
      >,
) {
  return writeContract({
    abi: registryFactoryABI,
    ...config,
  } as unknown as WriteContractArgs<typeof registryFactoryABI, TFunctionName>);
}

/**
 * Wraps __{@link prepareWriteContract}__ with `abi` set to __{@link registryFactoryABI}__.
 */
export function prepareWriteRegistryFactory<
  TAbi extends readonly unknown[] = typeof registryFactoryABI,
  TFunctionName extends string = string,
>(config: Omit<PrepareWriteContractConfig<TAbi, TFunctionName>, "abi">) {
  return prepareWriteContract({
    abi: registryFactoryABI,
    ...config,
  } as unknown as PrepareWriteContractConfig<TAbi, TFunctionName>);
}

/**
 * Wraps __{@link getContract}__ with `abi` set to __{@link safeABI}__.
 */
export function getSafe(config: Omit<GetContractArgs, "abi">) {
  return getContract({ abi: safeABI, ...config });
}

/**
 * Wraps __{@link readContract}__ with `abi` set to __{@link safeABI}__.
 */
export function readSafe<
  TAbi extends readonly unknown[] = typeof safeABI,
  TFunctionName extends string = string,
>(config: Omit<ReadContractConfig<TAbi, TFunctionName>, "abi">) {
  return readContract({
    abi: safeABI,
    ...config,
  } as unknown as ReadContractConfig<TAbi, TFunctionName>);
}

/**
 * Wraps __{@link getContract}__ with `abi` set to __{@link safeArbitratorABI}__.
 */
export function getSafeArbitrator(config: Omit<GetContractArgs, "abi">) {
  return getContract({ abi: safeArbitratorABI, ...config });
}

/**
 * Wraps __{@link readContract}__ with `abi` set to __{@link safeArbitratorABI}__.
 */
export function readSafeArbitrator<
  TAbi extends readonly unknown[] = typeof safeArbitratorABI,
  TFunctionName extends string = string,
>(config: Omit<ReadContractConfig<TAbi, TFunctionName>, "abi">) {
  return readContract({
    abi: safeArbitratorABI,
    ...config,
  } as unknown as ReadContractConfig<TAbi, TFunctionName>);
}

/**
 * Wraps __{@link writeContract}__ with `abi` set to __{@link safeArbitratorABI}__.
 */
export function writeSafeArbitrator<TFunctionName extends string>(
  config:
    | Omit<
        WriteContractPreparedArgs<typeof safeArbitratorABI, TFunctionName>,
        "abi"
      >
    | Omit<
        WriteContractUnpreparedArgs<typeof safeArbitratorABI, TFunctionName>,
        "abi"
      >,
) {
  return writeContract({
    abi: safeArbitratorABI,
    ...config,
  } as unknown as WriteContractArgs<typeof safeArbitratorABI, TFunctionName>);
}

/**
 * Wraps __{@link prepareWriteContract}__ with `abi` set to __{@link safeArbitratorABI}__.
 */
export function prepareWriteSafeArbitrator<
  TAbi extends readonly unknown[] = typeof safeArbitratorABI,
  TFunctionName extends string = string,
>(config: Omit<PrepareWriteContractConfig<TAbi, TFunctionName>, "abi">) {
  return prepareWriteContract({
    abi: safeArbitratorABI,
    ...config,
  } as unknown as PrepareWriteContractConfig<TAbi, TFunctionName>);
}
