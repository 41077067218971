export const navItems = [
  {
    name: "Gardens",
    href: "/gardens",
  },
  {
    name: "Docs",
    href: "/docs",
  },
];
